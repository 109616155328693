import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import PreviewGuide from "../PreviewGuide";
import getJSONfromPublic from "../getJSONfromPublic"; // Adjust this path to where your getJSONfromPublic function is located
import useOnScreen from "../useOnScreen"; // Adjust the import path as necessary

const FeaturedYouTubeVideo = () => {
  const guideRef = useRef(null);
  const isGuideVisible = useOnScreen(guideRef);
  const [guideData, setGuideData] = useState(null); // State to hold fetched guide data
  const [hasBeenVisible, setHasBeenVisible] = useState(false); // State to track if guide has ever been visible

  // Update hasBeenVisible to true once isGuideVisible is true
  useEffect(() => {
    if (isGuideVisible) {
      setHasBeenVisible(true);
    }
  }, [isGuideVisible]);

  useEffect(() => {
    const fetchGuideData = async () => {
      // Define your UID and session IDs for each type of guide
      const guideSessionId = "recording_1731128821025_198317fee150c"; // Replace with your actual session ID

      try {
        const guideData = await getJSONfromPublic(
          "mpobOAUK94XGpyovxFww1hQ0ogD3",
          guideSessionId
        );

        if (guideData)
          setGuideData({
            ...guideData,
            guideTitle: "Featured Example: " + guideData.guideTitle,
          });
      } catch (error) {
        console.error("There was a problem fetching guide data:", error);
      }
    };

    fetchGuideData();
  }, []);

  const videos = [
    {
      title:
        "Kimavi Extension - Explainer Video - Created with Kimavi Video Creator",
      url: "https://www.youtube.com/watch?v=HJ27CY8T_nY",
    },

    {
      title:
        "PowerPoint Mobile - Short Video - Created with Kimavi Video Creator",
      url: "https://www.youtube.com/shorts/Bj5Bo0NM5T0",
    },
  ];

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <h2 className="text-3xl font-bold text-center mb-6">
        Create Amazing Explainer Videos
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6">
        {videos.map((video, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-4 border border-gray-200"
          >
            <h3 className="text-xl font-semibold text-gray-700 mb-4 text-center">
              {video.title}
            </h3>
            <div className="relative" style={{ paddingTop: "56.25%" }}>
              <ReactPlayer
                url={video.url}
                className="absolute top-0 left-0 rounded-lg shadow-md"
                controls={true}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        ))}
      </div>
      <h2 className="text-3xl font-bold text-center mb-6 mt-6">
        Click by Click - How-To Video with GIF - Created via Kimavi Browser
        Extension
      </h2>

      <div className="m-1 p-1  rounded-lg shadow-lg" ref={guideRef}>
        {guideData && (isGuideVisible || hasBeenVisible) && (
          <PreviewGuide guideData={guideData} defaultTab={"video"} />
        )}
      </div>
    </div>
  );
};

export default FeaturedYouTubeVideo;
