// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics"; // <-- Import this
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBNuJceV6SZR4lsErlhV8qmBpUoQroscso",
  authDomain: "json-storage-bed47.firebaseapp.com",
  databaseURL: "https://json-storage-bed47-default-rtdb.firebaseio.com",
  projectId: "json-storage-bed47",
  storageBucket: "json-storage-bed47.appspot.com",
  messagingSenderId: "451919785006",
  appId: "1:451919785006:web:8623493929c1eb58fb83bf",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();
const analytics = getAnalytics(app); // <-- Initialize Analytics here
const database = getDatabase(app);

export { auth, db, storage, provider, analytics, database }; // <-- Export analytics
