// CatchAll.js

import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  lazy,
  Suspense,
} from "react";
import { useLocation } from "react-router-dom";
import Loader from "./components/Loader";
import { Link } from "react-router-dom";

import { FiHome, FiChevronRight } from "react-icons/fi"; // Import the breadcrumb icon
const PAGE_TYPE = {
  SUBJECT_MAIN: "Subject-Main",
  SUBJECT_MAIN_AI: "Subject-Main-AI",
  UNIT: "Unit",
  UNITAI: "UnitAI",
  REFERENCE: "Ref-Main",
  LANGUAGE: "Language",
  ALPHABET: "Language-Alphabet",
  PHRASEBOOK: "Language-Phrasebook-Category",
  VOCAB: "Language-Vocabulary-Category",
  VD: "Language-Visual-Dictionary-Category",
  NUMBER: "Language-Number",
  CHANNEL_MAIN_AI: "Channel-Main-AI",
};

function CatchAll() {
  const location = useLocation();
  const [isLocalhost, setIsLocalhost] = useState(false);

  // Scroll to top on page load or location change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Detect if the app is running on localhost
  useEffect(() => {
    if (window.location.hostname === "localhost") {
      setIsLocalhost(true);
    }
  }, []);

  const SubjectMain = lazy(() => import("./components/SubjectMain"));
  const SubjectMainAI = lazy(() => import("./components/SubjectMainAI"));
  const ReferenceMain = lazy(() => import("./components/ReferenceMain"));
  const ShowPublicQuiz = React.lazy(() =>
    import("./components/quiz/showPublicQuiz")
  );

  const SubjectSidebar = lazy(() => import("./components/SubjectSidebar"));
  const SubjectSidebarAI = lazy(() => import("./components/SubjectSidebarAI"));
  const UnitSidebarAI = lazy(() => import("./components/UnitSidebarAI"));
  const ReferenceSidebar = lazy(() => import("./components/ReferenceSidebar"));
  const UnitSidebar = lazy(() => import("./components/UnitSidebar"));
  const Sidebar = lazy(() => import("./components/Sidebar"));
  const CategoryMain = lazy(() => import("./components/CategoryMain"));
  const CategoryLanguage = lazy(() => import("./components/CategoryLanguage"));
  const LangaugeMainSidebar = lazy(() =>
    import("./components/LangaugeSidebar")
  );
  const LangaugeUnitSidebar = lazy(() =>
    import("./components/LangaugeUnitSidebar")
  );
  const UnitLanguage = lazy(() => import("./components/UnitLanguage"));
  const UnitLanguagePhrasebook = lazy(() =>
    import("./components/UnitLanguagePhrasebook")
  );
  const UnitLanguageVD = lazy(() => import("./components/UnitLanguageVD"));
  const UnitOld = lazy(() => import("./components/UnitOld"));
  const UnitAIDisplay = lazy(() => import("./components/UnitAI"));
  const UnitAIDisplayBAK = lazy(() => import("./components/UnitAIBAK"));
  const Markdown = lazy(() => import("./components/Markdown"));

  const [loading, setLoading] = useState(false);
  const [isBlog, setIsBlog] = useState(false);
  const [blogData, setBlogData] = useState("");

  const pathSegments = useMemo(() => {
    return location.pathname.split("/").filter((segment) => segment !== "");
  }, [location.pathname]);
  const lastSegment = pathSegments[pathSegments.length - 1];

  const [data, setData] = useState(null);
  const [vimeoId, setVimeoId] = useState(null);
  const [sidebarData, setSidebarData] = useState(null);
  const [aiQuizData, setAIQuizData] = useState({});
  const [quizData, setQuizData] = useState(null);
  const [unitData, setUnitData] = useState(null);
  const [hierarchyData, setHierarchyData] = useState(null);
  const [refinedHierarchyData, setRefinedHierarchyData] = useState(null);
  const [getDetailedJSON, setGetDetailedJSON] = useState(false);
  const MINIMUM_LOADER_DISPLAY_TIME = 0; // 1 second

  const [loaderStartTime, setLoaderStartTime] = useState(null);
  const [canHideLoader, setCanHideLoader] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [pageType, setPageType] = useState(null);
  const [unitAI, setUnitAI] = useState(false);
  const [unitAIData, setUnitAIData] = useState(null);

  function isIterable(obj) {
    return obj != null && typeof obj[Symbol.iterator] === "function";
  }

  async function parseJSON(response) {
    try {
      return await response.json();
    } catch (error) {
      // console.error("Failed to parse JSON:", error);
      return null;
    }
  }

  function generateTitleAndDescription() {
    const segments = location.pathname.split("/").filter((segment) => segment);

    let title = "Kimavi";
    let description = "Learn, Create, and Solve at Kimavi. ";

    if (segments.length) {
      title =
        segments
          .map((segment) => capitalize(segment.replace(/-/g, " ")))
          .join(" - ") + ` | ${title}`;
    }

    if (segments.includes("school")) {
      description +=
        "Empower your school learning journey with Generative AI. ";
    }

    if (segments.includes("college")) {
      description +=
        "Empower your college learning journey with Generative AI. ";
    }
    if (segments.includes("langauge")) {
      description +=
        "Empower your langauge learning journey with Generative AI. ";
    }
    if (segments.includes("references")) {
      description +=
        "Get insights from our references and empower your learning journey with Generative AI. ";
    }

    return {
      title,
      description,
    };
  }

  function capitalize(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  function isLanguageType(pageType) {
    // console.log("pageType", pageType);
    return pageType.startsWith(PAGE_TYPE.LANGUAGE + "-");
  }
  // When 'loading' changes to 'true', record the start time.
  useEffect(() => {
    if (loading) {
      setLoaderStartTime(Date.now());
    }
  }, [loading]);

  // Check if the loader has been displayed for at least 1 second.
  useEffect(() => {
    if (loading && loaderStartTime) {
      const timeElapsed = Date.now() - loaderStartTime;

      if (timeElapsed >= MINIMUM_LOADER_DISPLAY_TIME && dataLoaded) {
        setLoading(false); // Only hide loader if data is loaded and minimum time has passed
      } else {
        const timeoutDuration = dataLoaded
          ? MINIMUM_LOADER_DISPLAY_TIME - timeElapsed
          : MINIMUM_LOADER_DISPLAY_TIME;

        const timeoutId = setTimeout(() => {
          if (dataLoaded) {
            setLoading(false);
          }
        }, timeoutDuration);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [loading, loaderStartTime, dataLoaded]);

  const prepareHierarchyData = useCallback((data, pathSegments) => {
    let currentNode = data;
    // console.log("currentNode", currentNode);
    // console.log("currpathSegmentsentNode", pathSegments);

    if (!currentNode) {
      return null;
    }
    if (!isIterable(currentNode)) {
      return null;
    }
    // Remove "-old" from pathSegments
    const cleanedPathSegments = pathSegments.map((segment) =>
      segment.replace(/-old$/, "")
    );

    for (let segment of cleanedPathSegments) {
      let found = false;
      if (!isIterable(currentNode)) {
        // console.error("currentNode is not iterable:", currentNode);
        break; // break out of the outer loop if currentNode is not iterable
      }
      for (let child of currentNode) {
        if (
          child.title.toLowerCase().replace(/ /g, "-") === segment.toLowerCase()
        ) {
          if (child.children) {
            currentNode = child.children;
          } else {
            // console.log("child11", child);
            setGetDetailedJSON(true);
            currentNode = null;
          }
          found = true;
          break;
        } else {
          // console.log("child.title", child.title);
          // console.log("segment", segment);
        }
      }
      if (!found) {
        // console.log("not found");

        return null;
      }
    }

    return currentNode;
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch("/data.json");
      const data = await res.json();
      setHierarchyData(data);
      // setHierarchyData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Get the last segment
    const lastSegment = pathSegments[pathSegments.length - 1];
    // console.log("lastSegment", lastSegment);

    // Check if the last segment ends with "-blog"
    if (lastSegment && lastSegment.endsWith("-blog")) {
      setIsBlog(true);
    } else {
      setIsBlog(false);
    }

    // Check if the last segment matches the pattern of three alphabets followed by three numerics
    const unitAIPattern = /[a-zA-Z]{3}\d{3}$/;
    if (unitAIPattern.test(lastSegment)) {
      setUnitAI(true);
      // console.log("unitAI", unitAI);
      setPageType(PAGE_TYPE.UNITAI);

      // Fetch data from one level up
      const parentSegment = pathSegments[pathSegments.length - 2];
      // console.log("parentSegment", parentSegment);
      fetch(`/json2/${parentSegment}.json`)
        .then((response) => response.json())
        .then((data) => {
          // console.log("unitAIData", data);
          setUnitAIData(data);
        })
        .catch((error) => console.error("Error fetching unit AI data:", error));
    } else {
      setUnitAI(false);
    }
  }, [pathSegments]);

  useEffect(() => {
    // ... [rest of the local storage check]

    // Here, set the refined hierarchy data based on the URL path
    // console.log("hierarchyData22", hierarchyData);
    const refinedData = prepareHierarchyData(hierarchyData, pathSegments);
    setRefinedHierarchyData(refinedData);
  }, [pathSegments, prepareHierarchyData, hierarchyData]);

  useEffect(() => {
    const storedData = localStorage.getItem("hierarchyData");
    if (storedData) setHierarchyData(JSON.parse(storedData));
    if (!storedData) fetchData();
  }, []);

  const fetchQuizJSON = async (fileName) => {
    // console.log("fetchQuizJSON");
    let formattedFileName = fileName.replace(/ /g, "-").toLowerCase();
    const response = await fetch(`/json2/${formattedFileName}.json`);
    const response1 = await fetch(`/json2/${formattedFileName}-quiz.json`);
    if (response1.ok) {
      const data = await parseJSON(response1);
      if (data !== null) {
        setQuizData(data);
      }
    }

    if (response.ok) {
      const data = await parseJSON(response);
      if (data !== null) {
        setUnitData(data);
      }
    }
  };

  const fetchAIQuizJSON = async (fileName) => {
    // console.log("fetchAIQuizJSON");

    let formattedFileName = fileName.replace(/ /g, "-").toLowerCase();
    const response1 = await fetch(`/json2/${formattedFileName}-AIQuiz.json`);
    // console.log("fetchAIQuizJSON");
    if (response1.ok) {
      const data = await parseJSON(response1);
      if (data !== null) {
        // console.log("fetchAIQuizJSON");

        setAIQuizData(data);
      }
    }
  };

  const MAX_RETRIES = 10;

  const addToLocalStorage = (key, value) => {
    let retries = 0;
    while (retries < MAX_RETRIES) {
      try {
        // Try adding to localStorage
        localStorage.setItem(key, value);
        return true;
      } catch (error) {
        // If there's an error (probably due to being out of space), remove the oldest item
        const firstKey = localStorage.getItem("FIFO_KEYS")?.split(",")[0];
        if (firstKey) {
          localStorage.removeItem(firstKey);
          const remainingKeys = localStorage
            .getItem("FIFO_KEYS")
            .split(",")
            .slice(1)
            .join(",");
          localStorage.setItem("FIFO_KEYS", remainingKeys);
        } else {
          console.error("Failed to clean up localStorage:", error);
          return false;
        }
      }
      retries++;
    }
    console.error("Max retries hit while adding to localStorage");
    return false;
  };

  const fetchSideBarJSON = async (fileName) => {
    let formattedFileName = fileName.replace(/ /g, "-").toLowerCase();
    const storageKey = `sidebar_${formattedFileName}`;

    const storedData = JSON.parse(localStorage.getItem(storageKey) || "{}");
    const currentTime = new Date().getTime();

    if (
      storedData.timestamp &&
      currentTime - storedData.timestamp < 60 * 60 * 1000
    ) {
      setSidebarData(storedData.data);
      return;
    }

    const basePath = location.pathname.startsWith("/references/")
      ? "/blogJSON"
      : "/json2";
    const response = await fetch(`${basePath}/${formattedFileName}.json`);

    if (response.ok) {
      const responseData = await parseJSON(response);
      if (responseData !== null) {
        setSidebarData(data);
      }

      const success = addToLocalStorage(
        storageKey,
        JSON.stringify({
          timestamp: currentTime,
          data: responseData,
        })
      );

      if (success) {
        // Store the key in our FIFO list
        const currentKeys = localStorage.getItem("FIFO_KEYS")?.split(",") || [];
        localStorage.setItem(
          "FIFO_KEYS",
          [...currentKeys, storageKey].join(",")
        );
      }
    }
  };

  const fetchDetailsData = async () => {
    setLoading(true);
    // console.log("fetchDetailsData called");
    let basePath;
    if (lastSegment.endsWith("-blog")) {
      basePath = "/blog";
    } else if (location.pathname.startsWith("/references/")) {
      basePath = "/blogJSON";
    } else {
      basePath = "/json2";
    }

    let fetchPath = `${basePath}/${lastSegment}.json`;
    if (lastSegment.endsWith("-blog")) {
      fetchPath = `${basePath}/${lastSegment
        .replace(/-+/g, "-")
        .replace("-blog", "")}.mdx`;
    }

    try {
      // console.log("refinedHierarchyData", refinedHierarchyData);
      const response = await fetch(fetchPath);

      if (response.ok) {
        if (lastSegment.endsWith("-blog")) {
          const textContent = await response.text();

          setIsBlog(true);
          setBlogData(textContent); // Here, setting the text content,

          const quizRegex = /quiz: true/;
          const hasQuiz = quizRegex.test(textContent);

          // console.log("textContent", textContent);
          const microRegex = /micro: \[('|")(.*?)\1\]/;

          const match = microRegex.exec(textContent);
          const microTag = match && match[2];

          const vimeoIdRegex = /vimeoId: (\d+)/;
          const vimeoMatch = vimeoIdRegex.exec(textContent);
          const vimeoId = vimeoMatch && vimeoMatch[1];

          if (vimeoId) {
            setVimeoId(vimeoId);
          }
          // console.log("microTag", microTag);

          if (microTag) {
            fetchSideBarJSON(microTag.replace(":", ""));
          }

          if (hasQuiz) {
            return;
            // console.log("hasQuiz", hasQuiz);
            // Fetch the quiz data based on the path of the .mdx file
            const quizFetchPath = `${basePath}/${lastSegment.replace(
              "-blog",
              ".mdx-quiz"
            )}.json`;
            const quizResponse = await fetch(quizFetchPath);
            if (quizResponse.ok) {
              const quizData = await parseJSON(quizResponse);
              if (quizData !== null) {
                // Assuming you have a state-setting function named setQuizState

                setAIQuizData(quizData);
              }
            } else {
              // console.error("Request failed with status:", quizResponse.status);
            }
          }

          setData({ content: textContent }); // Here, setting the text content, you might need to adjust this part based on how you want to use the content.
        } else {
          const data = await response.json();
          setData(data);
          // console.log("fetchDetailsData called", data);
          setUnitData(data);
          // console.log("fetchDetailsData called", data);
          let determinedPageType;
          if (data.page_type) {
            determinedPageType = data.page_type;
          } else if (data[0] && data[0].page_type) {
            determinedPageType = data[0].page_type;
          }
          setPageType(determinedPageType); // Set the pageType

          let determinedAppName;
          if (data.app_name) {
            determinedAppName = data.app_name;
          } else if (data[0] && data[0].app_name) {
            determinedAppName = data[0].app_name;
          }

          if (determinedAppName) {
            await fetchSideBarJSON(determinedAppName);
          }
          if (data.page_quiz_link) {
            await fetchQuizJSON(lastSegment);
          }
          if (data.hasAIQuiz) {
            await fetchAIQuizJSON(lastSegment);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data for path:", error);
    } finally {
      setDataLoaded(true); // Data has been fetched
    }
  };

  useEffect(() => {
    // console.log("getDetailedJSON", getDetailedJSON);
    if (getDetailedJSON && !unitAI) {
      setQuizData(null);

      fetchDetailsData();
    }
  }, [getDetailedJSON, pathSegments, unitAI]);

  // console.log("pathSegments", pathSegments);
  const breadcrumbs = (
    <div className="flex flex-col bg-gray-300 w-full text-xs">
      <div className="grid grid-cols-none grid-rows-1 p-1 m-1">
        <div className="flex items-center">
          <Link to="/" className="breadcrumb-item">
            <span className="ml-1 text-gray-600">Home</span>{" "}
            {/* Adjusted for spacing */}
          </Link>

          {pathSegments.map((segment, index) => {
            // Remove '-blog' if it exists
            let displaySegment = segment.endsWith("-blog")
              ? segment.slice(0, -5)
              : segment;

            // Split the displaySegment by dashes
            let segments = displaySegment.split("-");
            // Check if the last segment matches the pattern of three alphabets followed by three numerals
            const lastSegment = segments[segments.length - 1];
            if (/^[a-zA-Z]{3}\d{3}$/.test(lastSegment)) {
              // Remove both letters and numerals from the last segment
              segments[segments.length - 1] = lastSegment.replace(
                /[a-zA-Z0-9]/g,
                ""
              );
            }

            // Join the segments back together
            displaySegment = segments.join("-");

            // Convert hyphens to spaces and capitalize each word
            displaySegment = displaySegment
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");

            // Limit the display length to 50 characters
            displaySegment =
              displaySegment.length > 50
                ? displaySegment.slice(0, 50) + "..."
                : displaySegment;

            // console.log(displaySegment, index); // This is for debugging, you can remove it once it works correctly

            return (
              <React.Fragment key={index}>
                <FiChevronRight className="text-gray-600 mx-1" />{" "}
                {/* Added mx-1 for margin */}
                <Link
                  to={`/${pathSegments.slice(0, index + 1).join("/")}`}
                  className="breadcrumb-item text-gray-500"
                  target="_self" // Forces full refresh on click
                >
                  {displaySegment}
                </Link>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );

  if (loading && !canHideLoader) {
    return <Loader />;
  }
  if (!hierarchyData) {
    return (
      <>
        <Suspense fallback={<Loader />}>
          <Sidebar />
          <Loader />
        </Suspense>
      </>
    );
  }

  const renderChannelMainAI = (data) => {
    // console.log("data", data);
    return (
      <>
        <Suspense fallback={<Loader />}>
          <Sidebar />
          <div className="flex flex-col w-full bg-gray-300">
            {breadcrumbs}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
              {Object.keys(data.Apps).map((key, index) => {
                const app = data.Apps[key];
                return (
                  <div
                    key={index}
                    className="card p-4 bg-white shadow-lg rounded-lg"
                  >
                    <Link to={`${location.pathname}/${app.URL}`}>
                      <div
                        className="w-full h-60 bg-center bg-cover mb-4"
                        style={{
                          backgroundImage: `url(${app.image})`,
                        }}
                      ></div>
                      <h3 className="text-2xl text-gray-500 mt-4 mb-2">
                        {app.title}
                      </h3>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </Suspense>
      </>
    );
  };

  if (Array.isArray(refinedHierarchyData) && refinedHierarchyData.length > 0) {
    if (refinedHierarchyData[0].page_type === PAGE_TYPE.CHANNEL_MAIN_AI) {
      return renderChannelMainAI(refinedHierarchyData[0]);
    }

    return (
      <>
        <Suspense fallback={<Loader />}>
          <div className="flex flex-col md:flex-row w-full bg-gray-300">
            {/* Sidebar */}
            <div className="w-full md:w-72">
              <Sidebar />
            </div>

            {/* Content Section */}
            <div className="w-full md:w-3/4 bg-gray-300">
              {/* Breadcrumbs */}
              <div className="w-full p-4">{breadcrumbs}</div>

              {/* Cards */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
                {refinedHierarchyData.map((node, index) => {
                  const urlTitle = node.title.toLowerCase().replace(/ /g, "-");

                  return (
                    <div
                      key={node.title}
                      className="card p-4 bg-white shadow-lg rounded-lg"
                    >
                      <Link to={`${location.pathname}/${urlTitle}`}>
                        {node.image ? (
                          <div
                            className="relative h-48 bg-cover bg-center rounded-lg"
                            style={{ backgroundImage: `url(${node.image})` }}
                          >
                            <h3 className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-2xl p-4 rounded-b-lg">
                              {node.title}
                            </h3>
                          </div>
                        ) : (
                          <h3 className="text-2xl text-gray-500 mt-4 mb-2">
                            {node.title}
                          </h3>
                        )}
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Suspense>
      </>
    );
  } else {
    if (unitAI && unitAIData !== null) {
      // console.log("unitAIData", unitAIData);
      return (
        <>
          {/* <UnitSidebar data={unitAIData} /> */}

          <Suspense fallback={<Loader />}>
            <div className="flex flex-col md:flex-row w-full">
              <div className=" w-72">
                <UnitSidebarAI data={unitAIData} />
              </div>
              <div className="flex flex-col w-full m-1">
                {isLocalhost ? (
                  <>
                    <div className="w-full ">{breadcrumbs}</div>
                    <UnitAIDisplayBAK data={unitAIData} />
                  </>
                ) : (
                  <>
                    <div className="w-full ">{breadcrumbs}</div>
                    <UnitAIDisplay data={unitAIData} />
                  </>
                )}
              </div>
            </div>
          </Suspense>
        </>
      );
    } else {
      // console.log(data);
      return (
        <div className="grid grid-cols-1 md:flex md:grid-cols-none h-full w-full ">
          {data ? (
            <>
              <Suspense fallback={<Loader />}>
                {pageType === PAGE_TYPE.SUBJECT_MAIN_AI && (
                  <>
                    <SubjectSidebarAI data={data} />
                    <SubjectMainAI data={data} className="flex-grow" />
                  </>
                )}

                {pageType === PAGE_TYPE.SUBJECT_MAIN && (
                  <>
                    <SubjectSidebar data={data} />

                    <SubjectMain data={data} className="flex-grow" />
                  </>
                )}

                {pageType === PAGE_TYPE.LANGUAGE && (
                  <>
                    <LangaugeMainSidebar data={data} />
                    <CategoryLanguage data={data} className="flex-grow" />
                  </>
                )}

                {(pageType === PAGE_TYPE.PHRASEBOOK ||
                  pageType === PAGE_TYPE.VOCAB) && (
                  <>
                    <LangaugeUnitSidebar data={sidebarData} nested={true} />
                    <UnitLanguagePhrasebook
                      data={sidebarData}
                      dataSlide={data}
                      className="flex-grow"
                    />
                  </>
                )}

                {pageType === PAGE_TYPE.VD && (
                  <>
                    <LangaugeUnitSidebar data={sidebarData} nested={true} />
                    <UnitLanguageVD
                      data={sidebarData}
                      dataSlide={data}
                      className="flex-grow"
                    />
                  </>
                )}

                {pageType &&
                  pageType !== PAGE_TYPE.VD &&
                  pageType !== PAGE_TYPE.PHRASEBOOK &&
                  pageType !== PAGE_TYPE.VOCAB &&
                  isLanguageType(pageType) && (
                    <>
                      <LangaugeUnitSidebar data={sidebarData} />
                      <UnitLanguage
                        data={sidebarData}
                        dataSlide={data}
                        className="flex-grow"
                      />
                    </>
                  )}

                {data && data.Chapters && pageType === PAGE_TYPE.REFERENCE && (
                  <>
                    <ReferenceSidebar data={data} />
                    <ReferenceMain data={data} className="flex-grow" />
                  </>
                )}

                {isBlog && sidebarData && (
                  <>
                    <ReferenceSidebar data={sidebarData} nested={true} />
                    <div className="flex flex-col w-full">
                      <div className="flex">{breadcrumbs}</div>
                      <div>
                        <Markdown
                          content={blogData}
                          data={sidebarData}
                          vimeoId={vimeoId}
                          quizAI={aiQuizData}
                        />
                      </div>
                    </div>
                  </>
                )}

                {pageType === PAGE_TYPE.UNIT && (
                  <>
                    {unitData && sidebarData && (
                      <>
                        <div className="flex flex-col w-full">
                          <div className="flex">{breadcrumbs}</div>
                          <div>
                            <UnitOld
                              data={unitData}
                              quiz={quizData}
                              quizAI={aiQuizData}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                {!isBlog &&
                  pageType &&
                  pageType !== PAGE_TYPE.SUBJECT_MAIN &&
                  pageType !== PAGE_TYPE.SUBJECT_MAIN_AI &&
                  pageType !== PAGE_TYPE.REFERENCE &&
                  pageType !== PAGE_TYPE.UNIT &&
                  pageType !== PAGE_TYPE.UNITAI &&
                  pageType !== PAGE_TYPE.LANGUAGE &&
                  !isLanguageType(pageType) && (
                    <>
                      <Sidebar />
                      <CategoryMain data={data} className="flex-grow" />
                    </>
                  )}
              </Suspense>
            </>
          ) : (
            <div>
              <Loader />
            </div>
          )}
        </div>
      );
    }
  }
}

export default CatchAll;
