import React, { useState, useEffect } from "react";
import { MdOutlineAutoAwesome } from "react-icons/md";
import { Link } from "react-router-dom";

// Updated professions and descriptions to match the new marketing message
const professions = [
  "Customer Support Teams",
  "Sales Teams",
  "Marketing Professionals",
  "Product Managers",
  "Content Creators",
  "L&D Professionals",
];

function FeaturedGuide() {
  const [professionIndex, setProfessionIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("slide-in-up"); // Initial state for slide-in

  useEffect(() => {
    const professionChangeInterval = setInterval(() => {
      // Start fade-out
      setAnimationClass("fade-out");

      // After fade-out, change profession and fade-in
      setTimeout(() => {
        setProfessionIndex((prevIndex) => (prevIndex + 1) % professions.length);
        setAnimationClass("slide-in-up"); // Slide-in the new text
      }, 500); // Wait 500ms for fade-out before changing the profession
    }, 5000); // Change the profession every 5 seconds

    return () => clearInterval(professionChangeInterval);
  }, []);

  return (
    <>
      <style>{`
      @keyframes fadeOut {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
  
      @keyframes slideInUp {
        0% {
          opacity: 0;
          transform: translateY(100%);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
  
      .fade-out {
        animation: fadeOut 0.5s forwards;
      }
  
      .slide-in-up {
        animation: slideInUp 0.5s ease-out forwards;
      }
    `}</style>

      <section className="bg-gradient-to-r from-blue-50 to-gray-100 py-6 px-6 rounded">
        <h3
          className={`text-sm md:text-2xl lg:text-3xl text-gray-500 font-medium text-center ${animationClass}`}
        >
          Revolutionize Sales Success via Sales Agent{" "}
          <div className="mb-5 mt-5">
            <span className="bg-gray-400 text-white text-sm md:text-xl lg:text-2xl px-2 py-2 m-1 rounded-2xl">
              {professions[professionIndex]}
            </span>
          </div>
        </h3>
        <h3 className="text-sm md:text-lg text-gray-400 font-medium text-center mt-4">
          Accelerate Sales Success with AI-powered how-to guides, video
          tutorials, and Sales Agent solutions!
        </h3>

        <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2 lg:grid-cols-3 place-items-center w-full">
          {/* Card for AI Video Creator */}
          <div className="w-full p-4 bg-white border border-gray-300 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col h-full min-h-[400px]">
            <MdOutlineAutoAwesome className="text-blue-500 text-3xl self-center mb-4" />
            <div className="flex flex-col items-center">
              <h3 className="text-lg md:text-xl font-semibold text-gray-900 text-center">
                Product Videos (Communicate Value)
              </h3>
            </div>
            <p className="text-gray-700 text-md md:text-lg mb-4 leading-relaxed text-center mt-2 mb-2">
              Communicate product value with AI generated scripts and your
              product images.
            </p>
            <div className="space-y-2 mb-4">
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Communicate product value with videos
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Generate videos in landscape, portrait, and one-minute short
                formats
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Works with mobile app and desktop screenshots
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Create stunning video tutorials in minutes with professional AI
                voices
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Record your own voice and video
              </div>
            </div>
            <div className="mt-auto">
              <Link to="/ai">
                <div className="w-full text-xl text-cyan bg-black hover:bg-blue-600 py-2 rounded">
                  Video Creator
                </div>
              </Link>
            </div>
          </div>

          {/* Card for Chrome Extension */}
          <div className="w-full p-4 bg-white border border-gray-300 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col h-full min-h-[400px]">
            <MdOutlineAutoAwesome className="text-blue-500 text-3xl self-center mb-4" />
            <div className="flex flex-col items-center">
              <h3 className="text-lg md:text-xl font-semibold text-gray-900 text-center">
                Product Guides (In-App Help)
              </h3>
            </div>
            <p className="text-gray-700 text-md md:text-lg mb-4 leading-relaxed text-center mt-2 mb-2">
              Supercharge your workflow with our AI-powered extension that
              captures clicks and generates How To scripts.
            </p>
            <div className="space-y-2 mb-4">
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Effortlessly create and share How-To Videos, Shorts and
                Documentation
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Step-by-step screen capture with AI scripts
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Boost productivity with automation
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Integrates seamlessly with our Web Editor
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Full control over data with On-Prem install
              </div>
            </div>
            <div className="mt-auto">
              <a
                href="https://chromewebstore.google.com/detail/kimavi-create-guides-with/kcleenakjlelbmchbkfpgoldgglhdpne?hl=en&authuser=0&pli=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="w-full text-xl text-cyan bg-black hover:bg-blue-600 py-2 rounded">
                  Capture Screen Shots (Extension)
                </div>
              </a>
            </div>
          </div>

          {/* New Card for Sales Agent */}
          <div className="w-full p-4 bg-white border border-gray-300 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col h-full min-h-[400px]">
            <MdOutlineAutoAwesome className="text-blue-500 text-3xl self-center mb-4" />
            <div className="flex flex-col items-center">
              <h3 className="text-lg md:text-xl font-semibold text-gray-900 text-center">
                Sales Agent (Product Chat for pre-sales, help and onboarding)
              </h3>
            </div>
            <p className="text-gray-700 text-md md:text-lg mb-4 leading-relaxed text-center mt-2 mb-2">
              Elevate your customer and prospect experience with a custom
              ChatGPT trained exclusively on your product.
            </p>
            <div className="space-y-2 mb-4">
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Seamlessly handle product-related questions
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Guide users through features with personalized responses
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Provide step-by-step How-To guides
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Enhance customer support
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Boost lead generation
              </div>
            </div>
            <div className="mt-auto">
              <Link to="/chat">
                <div className="w-full text-xl text-cyan bg-black hover:bg-blue-600 py-2 rounded">
                  Sales Agent
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex justify-center mb-2 mt-10">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-400 mb-8 text-center">
            Rapidly Create Sales Agent, How-To Guides & Videos. Engage Customers
            and prospects with Sales Agent trained on your product.
          </h2>
        </div>
      </section>
    </>
  );
}

export default FeaturedGuide;
