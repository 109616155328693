// Kimavi Features and Rainbow Colors
const rainbowColors = [
  "bg-red-200",
  "bg-orange-200",
  "bg-yellow-200",
  "bg-green-200",
  "bg-blue-200",
  "bg-indigo-200",
  "bg-purple-200",
];

const kimaviFeatures = [
  {
    title: "Accelerate MQL – Automate Lead Generation",
    description:
      "Use our AI-driven Sales Agent to connect with web visitors, collect emails and qualify leads, and streamline your marketing funnel—eliminating the need for manual oversight. Capture precise insights into vistor pain points and deliver immediate, intelligent responses.",
    icon: "✅",
  },
  {
    title: "Accelerate SQL – Automate Prospect Qualification",
    description:
      "Provide intelligent, in-depth answers to FAQs, deliver on-the-spot product demos, and instantly share how-to solutions—reducing the time and cost of engaging prospects. Understand, and document BANT - Budget, Authority, Needs and Timeline via conversations and parallel autonomous research.",
    icon: "✅",
  },
  {
    title: "Drive Retention and Upsell – Automate Onboarding and Documentation",
    description:
      "Simplify onboarding with interactive, step-by-step guidance. Automatically create visually appealing, video-enriched documentation—enhancing the customer experience. Seamlessly upsell through in-app messaging and targeted recommendations.",
    icon: "✅",
  },
];
export { kimaviFeatures, rainbowColors };
