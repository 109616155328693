// HomeLayout.js

import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "./AuthProvider";
import Hero from "./components/Hero";
import FeaturedGuide from "./components/FeaturedGuide";
import { Featured } from "./constants/featured";
import Sidebar from "./components/Sidebar";

import { useNavigate } from "react-router-dom";
import useAppLocation from "./useAppLocation";
import FeaturedYouTubeVideo from "./components/FeaturedYouTube";
import { gradientOptions } from "./gradientOptions";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import ChatBot from "./chatbot";
import { Link } from "react-router-dom";
import { kimaviFeatures, rainbowColors } from "./KimaviFeatures";

const shuffleArray = (array) => {
  // Shuffles an array
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

function HomeLayout() {
  const handleGuideSelect = (guide) => {
    navigate(
      `/view?id=${guide.recordingSessionId}&source=server&uid=${guide.uid}`
    );
  };

  const guidesPerPage = 3;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleGuides, setVisibleGuides] = useState(
    Featured.slice(0, guidesPerPage)
  );

  const navigate = useNavigate();
  const { userEmail, planId, isPaid, isAuthChecking, idToken } =
    useContext(AuthContext);
  const { shouldRenderSidebar } = useAppLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedGuide, setSelectedGuide] = useState(null);
  const [isExploreTabActive, setIsExploreTabActive] = useState(false);

  // State for rotating messages
  const [learningMessageIndex, setLearningMessageIndex] = useState(0);
  const [videoMessageIndex, setVideoMessageIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("");

  // Arrays of messages to rotate
  const learningMessages = [
    "Edu Tutorials",
    "How-To Tutorials",
    "Mobile Tutorials",
  ];

  const videoMessages = [
    "⚡ Sales Agent - Sales Made Easy ⚡",
    "⚡ How-To Guides - Onboarding Made Easy ⚡",
    "⚡ Video Tutorials - Demos Made Easy ⚡",
    "⚡ Prospect Analytics - Sales Made Easy ⚡",

    "💡 Sales Agent - Sales Made Better 💡",
    "💡 How-To Guides - Onboarding Made Better 💡",
    "💡 Video Tutorials - Demos Made Better 💡",
    "💡 Prospect Analytics - Sales Made Better 💡",

    "🎉 Sales Agent - Sales Made Joyful 🎉",
    "🎉 How-To Guides - Onboarding Made Joyful 🎉",
    "🎉 Video Tutorials - Demos Made Joyful 🎉",
    "🎉 Prospect Analytics - Sales Made Joyful 🎉",
  ];

  // Add CSS animations
  useEffect(() => {
    const styleTag = document.createElement("style");
    styleTag.innerHTML = `
      @keyframes fadeOut {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      @keyframes slideInUp {
        0% {
          opacity: 0;
          transform: translateY(100%);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .fade-out {
        animation: fadeOut 1s forwards;
      }

      .animate-slide-in-up {
        animation: slideInUp 1s ease-out forwards;
      }

      @keyframes rainbowAnimation {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
    `;
    document.head.appendChild(styleTag);
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  useEffect(() => {
    const shuffledGuides = shuffleArray(Featured);
    setVisibleGuides(shuffledGuides.slice(0, guidesPerPage));
  }, []);

  const handleRefresh = () => {
    const shuffledGuides = shuffleArray(Featured);
    setCurrentIndex(0);
    setVisibleGuides(shuffledGuides.slice(0, guidesPerPage));
  };

  const handleNext = () => {
    const newIndex =
      currentIndex + guidesPerPage < Featured.length
        ? currentIndex + guidesPerPage
        : currentIndex;
    setCurrentIndex(newIndex);
    setVisibleGuides(Featured.slice(newIndex, newIndex + guidesPerPage));
  };

  const handlePrev = () => {
    const newIndex =
      currentIndex - guidesPerPage >= 0 ? currentIndex - guidesPerPage : 0;
    setCurrentIndex(newIndex);
    setVisibleGuides(Featured.slice(newIndex, newIndex + guidesPerPage));
  };

  // Effect to rotate learning messages every 5 seconds with fade out and fade in
  useEffect(() => {
    const learningInterval = setInterval(() => {
      // Trigger fade out animation
      setAnimationClass("fade-out");

      // Wait for fade out to finish, then change message and fade in
      setTimeout(() => {
        setLearningMessageIndex(
          (prevIndex) => (prevIndex + 1) % learningMessages.length
        );
        setAnimationClass("animate-slide-in-up");
      }, 1000);
    }, 5000);

    return () => clearInterval(learningInterval);
  }, []);

  // Effect to rotate video messages every 5 seconds with fade out and fade in
  useEffect(() => {
    const videoInterval = setInterval(() => {
      // Trigger fade out animation
      setAnimationClass("fade-out");

      // Wait for fade out to finish, then change message and fade in
      setTimeout(() => {
        setVideoMessageIndex(
          (prevIndex) => (prevIndex + 1) % videoMessages.length
        );
        setAnimationClass("animate-slide-in-up");
      }, 1000);
    }, 5000);

    return () => clearInterval(videoInterval);
  }, []);

  useEffect(() => {
    if (selectedGuide) {
      const { uid, recordingSessionId } = selectedGuide;
      navigate(`/video?id=${recordingSessionId}&source=server&uid=${uid}`);
    }
  }, [selectedGuide]);

  const handleTabChange = (tabIndex) => {
    setIsExploreTabActive(tabIndex === 1);
  };

  return (
    <div className="grid grid-cols-1 md:flex md:grid-cols-none h-full w-full">
      {shouldRenderSidebar && isSidebarOpen && (
        <Sidebar
          onGuideSelect={setSelectedGuide}
          onTabChange={handleTabChange}
        />
      )}

      <div className="flex flex-col m-2 p-2 w-full">
        {false ? (
          <div> loading... </div>
        ) : (
          <>
            {/* Hero Section */}

            <section className="bg-gradient-to-r from-red-500 via-orange-500 via-yellow-500 via-green-500 via-blue-500 via-indigo-500 to-purple-500 rounded-lg shadow-lg p-8 m-2 ">
              <div className="text-center">
                <h1 className="text-4xl md:text-6xl lg:text-8xl font-extrabold mb-4 text-white animate-pulse">
                  Engage Visitors - Drive Sales
                </h1>
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold mb-4 text-white">
                  With Chat, Video and Guided Automation
                </h1>

                <div className="flex justify-center items-center">
                  <div className="bg-gradient-to-r from-yellow-100 via-orange-100 to-red-100 shadow-lg rounded-xl p-6 max-w-68xl mt-6 mb-6 text-center">
                    <h2 className="text-2xl font-bold text-gray-800 mb-4">
                      Upgrade Your Web Site with a Video & Guide Powered AI
                      Sales Agent
                    </h2>
                    <p className="text-lg text-gray-600 mb-6">
                      Easily create, train, and tailor your AI Sales Agent —
                      Call Kimavi APIs to empower your existing chatbot
                    </p>
                    <Link
                      to="/create-chatbot"
                      className="bg-gray-800 text-teal-300 font-bold py-3 px-8 rounded-full shadow-md hover:bg-teal-600 hover:shadow-lg transition duration-300 inline-block"
                    >
                      Launch Your AI Sales Agent – Start Now
                    </Link>
                    <p className="text-lg text-gray-600 mt-6">
                      Capture leads, understand pain points, deliver product
                      demos, address objections, and drive sales success
                    </p>
                  </div>
                </div>

                {/* What is Kimavi section */}
                <div className="container mx-auto px-4 text-center my-8 mt-8">
                  <h2 className="text-3xl font-extrabold text-gray-50 mb-8 mt-8 animate-pulse">
                    Don't annoy your visitors - with a dumb chatbot - Empower
                    with AI, Video and Guided Automation
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mx-auto max-w-screen-lg">
                    {kimaviFeatures.map((feature, index) => (
                      <div
                        key={index}
                        className={`${
                          rainbowColors[index % rainbowColors.length]
                        } transition duration-200 p-6 rounded-lg shadow-lg text-center`}
                      >
                        <h3 className="text-2xl font-semibold text-gray-800">
                          {feature.icon} {feature.title}
                        </h3>
                        <p className="text-gray-700 mt-2">
                          {feature.description}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Max Ball Quote Section */}
                <div className="flex flex-col md:flex-row items-center bg-gray-100 p-6 rounded-lg shadow-md mt-6 max-w-4xl mx-auto">
                  <a
                    href="https://www.forrester.com/content-service/api/v1/content/public/image/cms/public/images/eddd856581894591970721dd9b4c9a45/ee93cfe30ae4b9d0ee649876b4dc3bb3/Max-Ball.png"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://www.forrester.com/content-service/api/v1/content/public/image/cms/public/images/eddd856581894591970721dd9b4c9a45/ee93cfe30ae4b9d0ee649876b4dc3bb3/Max-Ball.png"
                      alt="Max Ball"
                      className="rounded-full mr-6 mb-4 md:mb-0"
                    />
                  </a>
                  <div>
                    <blockquote className="text-lg italic text-gray-800">
                      "Out of the box, a conversational AI system will do
                      nothing for customers — someone must build applications.
                      Tools are required to build prompts for genAI, call LLMs,
                      and define natural language understanding (NLU)
                      interactions."
                    </blockquote>
                    <div className="text-right mt-2 text-sm text-gray-700">
                      - Max Ball, Forrester Research
                    </div>
                    <a
                      href="https://drive.google.com/file/d/1jnqSC0JuPBoEOOTY76LHFbSx1_RDnZ1d/view?usp=sharing"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline mt-2 block"
                    >
                      Read the full report here
                    </a>
                  </div>
                </div>

                {/* Added Cost Reduction and Benefits Section */}
                <div className="flex flex-col items-center bg-gradient-to-r from-blue-100 via-teal-100 to-green-100 shadow-lg rounded-xl p-1 rounded-lg shadow-md mt-6 max-w-6xl mx-auto">
                  <p className="text-2xl text-gray-800 text-center mt-3 mb-3">
                    Agent can not only answer questions, show videos, but it can
                    also complete actions on behalf of the user
                  </p>
                  <p className="text-lg text-gray-800 text-center">
                    Agent can trigger walkthroughs (Step by step Execution -
                    DoItForMe) - This is a game changer for sales, onboarding
                    and support
                  </p>
                </div>
                <h2 className="text-3xl font-extrabold text-gray-50 mb-8 mt-8">
                  Give Video, Guide and Automation Super Powers to your Chatbots
                  (or adopt ours!)
                </h2>
                <div className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 mt-10">
                  <FeaturedGuide />
                </div>
              </div>
            </section>

            {/* Featured How-To Guides */}
            <div className="container mx-auto px-4 text-center mb-6 mt-6">
              <h2 className="text-xl font-bold text-gray-800 mb-4">
                Featured How-To Guides - Learn #Sora
              </h2>
              <div className="flex flex-col md:flex-row justify-between items-center">
                <button
                  onClick={handlePrev}
                  aria-label="Previous guides"
                  disabled={currentIndex === 0}
                  className={`rounded-full m-2 p-2 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ${
                    currentIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  <MdArrowBackIos className="text-gray-800" />
                </button>
                <div className="flex flex-col md:flex-row space-x-0 md:space-x-4 overflow-hidden justify-center mb-4 md:mb-0">
                  {visibleGuides.map((guide, index) => (
                    <div
                      key={index}
                      className="max-w-md rounded-xl shadow-md overflow-hidden md:max-w-2xl mb-4 md:mb-0 
                     bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 transform hover:scale-105 transition-transform"
                      onClick={() => handleGuideSelect(guide)}
                    >
                      <div className="p-8">
                        <a
                          href="#"
                          onClick={(e) => e.preventDefault()}
                          className="block mt-1 text-lg leading-tight font-medium text-white hover:underline"
                        >
                          {guide.guideTitle}
                        </a>
                        <p className="mt-2 text-white font-light">
                          #{guide.tags.join(" #")}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  onClick={handleNext}
                  aria-label="Next guides"
                  disabled={currentIndex + guidesPerPage >= Featured.length}
                  className={`rounded-full m-2 p-2 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ${
                    currentIndex + guidesPerPage >= Featured.length
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                >
                  <MdArrowForwardIos className="text-gray-800" />
                </button>
              </div>
            </div>

            {/* Customer Analytics Section */}
            <section className="bg-gradient-to-b from-blue-50 to-gray-100 py-12">
              <div className="container mx-auto space-y-12 px-6 md:px-12">
                <div className="text-center">
                  <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-800 mb-10">
                    Try Our Video-Enabled Sales Agent
                  </h2>
                  <div className="mt-5 mb-5 p-3 m-3 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                    <div className="flex justify-center m-3 p-3">
                      <iframe
                        src="/iframe-embed?botId=kimavi-101"
                        width="400"
                        height="600"
                        title="Kimavi ChatBot"
                        style={{
                          border: "1px solid black",
                          borderRadius: "20px",
                        }}
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Vision Section */}
            <section className="mb-12 mt-12 m-1 p-1">
              <div className="bg-gradient-to-r from-gray-400 to-orange-500 rounded-lg shadow-lg p-8">
                <h2 className="text-4xl font-bold mb-4 text-center text-white">
                  Vision
                </h2>
                <p className="text-lg md:text-xl text-center text-white">
                  To empower businesses worldwide to achieve exceptional sales
                  success through AI-enabled sales agents enriched with product
                  demo videos and how-to guides, transforming every prospect
                  interaction into a personalized, engaging, and impactful
                  experience.
                </p>
              </div>
            </section>

            {/* How-To Guides - Examples Section */}
            <section className="bg-gradient-to-b from-blue-50 to-gray-100 py-12">
              <div className="container mx-auto space-y-12 px-6 md:px-12">
                <div className="text-center">
                  <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-800 mb-8">
                    Build and Share Product Demo Videos in Minutes!
                  </h2>
                  <div className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 mt-10">
                    <FeaturedYouTubeVideo />
                  </div>
                </div>
              </div>
            </section>

            {/* Why Us? Section */}
            <section className="mb-12 m-1 p-1">
              <div className="bg-gradient-to-r from-gray-400 to-pink-500 rounded-lg shadow-lg p-8 space-y-6">
                {/* Section Header */}
                <h2 className="text-4xl font-bold mb-4 text-center text-white">
                  Why Kimavi?
                </h2>
                <div className="bg-gradient-to-r from-gray-500 to-purple-500 rounded-lg shadow-lg p-8">
                  <p className="text-lg md:text-xl text-center text-white">
                    AI Sales Agents are only as effective as their training
                    data. Without high-quality data, they may provide inaccurate
                    or unhelpful responses, leading to customer frustration.
                  </p>
                </div>

                {/* Reusable Card Component */}
                <div className="space-y-4">
                  {/* Card 1 */}
                  <div className="bg-gradient-to-r from-orange-500 to-purple-500 rounded-lg shadow-lg p-8">
                    <p className="text-lg md:text-xl text-center text-white">
                      Our tools let you create high-quality, rich media content—
                      including videos, how-to guides, GIFs, documents, and
                      audio—to train your Sales Agents effectively, ensuring
                      accurate and helpful responses that drive customer
                      satisfaction.
                    </p>
                  </div>

                  {/* Card 2 */}
                  <div className="bg-gradient-to-r from-yellow-600 to-red-400 rounded-lg shadow-lg p-8">
                    <p className="text-lg md:text-xl text-center text-white">
                      Kimavi enhances customer engagement and sales success
                      across pre-sales, onboarding, and support through a suite
                      of tools including a custom AI-powered Sales Agents,
                      in-app guides, and video tutorials.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* Beliefs and Commitments */}
            <section className="mb-12 m-1 p-1">
              <h2 className="text-4xl font-bold mb-8 text-center text-gray-800">
                Our Beliefs and Commitments
              </h2>

              {/* Belief Cards */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Belief 1 */}
                <div className="bg-white rounded-lg shadow-lg p-6">
                  <h3 className="text-2xl font-bold mb-4 text-indigo-600">
                    1. Sales Success is Our Priority
                  </h3>
                  <p className="text-lg mb-4 text-gray-700">
                    At Kimavi, we believe businesses thrive when sales succeed.
                    Our tools help you engage prospects, assist customers, and
                    drive sales success through:
                  </p>
                  <ul className="list-disc list-inside text-lg space-y-2 text-gray-700">
                    <li>
                      <strong>Sales Agent</strong>: Train a Sales Agent on your
                      products to engage visitors, collect leads, and provide
                      personalized demos.
                    </li>
                    <li>
                      <strong>How-To Guides</strong>: Create step-by-step guides
                      effortlessly with our AI-powered browser extension.
                    </li>
                    <li>
                      <strong>Video Tutorials</strong>: Develop engaging video
                      content quickly using our Video Script Creator.
                    </li>
                  </ul>
                </div>

                {/* Belief 2 */}
                <div className="bg-white rounded-lg shadow-lg p-6">
                  <h3 className="text-2xl font-bold mb-4 text-green-600">
                    2. AI is Transforming Sales Success
                  </h3>
                  <p className="text-lg mb-4 text-gray-700">
                    We believe artificial intelligence is revolutionizing how
                    businesses interact with prospects. By harnessing AI, we
                    enable:
                  </p>
                  <ul className="list-disc list-inside text-lg space-y-2 text-gray-700">
                    <li>
                      <strong>Instant Answers</strong>: Provide immediate
                      responses to customer inquiries with AI-powered Sales
                      Agents.
                    </li>
                    <li>
                      <strong>Personalized Content</strong>: Tailor interactions
                      based on prospect questions and preferences.
                    </li>
                    <li>
                      <strong>Scalable Solutions</strong>: Handle prospect
                      objections and questions without compromising quality.
                    </li>
                  </ul>
                </div>

                {/* Belief 3 */}
                <div className="bg-white rounded-lg shadow-lg p-6">
                  <h3 className="text-2xl font-bold mb-4 text-blue-600">
                    3. Human + AI Equals Unmatched Sales
                  </h3>
                  <p className="text-lg mb-4 text-gray-700">
                    While AI enhances efficiency, the human touch remains
                    invaluable. Our platform ensures a harmonious blend of both:
                  </p>
                  <ul className="list-disc list-inside text-lg space-y-2 text-gray-700">
                    <li>
                      <strong>AI Assistance</strong>: Automate repetitive tasks
                      and provide quick answers.
                    </li>
                    <li>
                      <strong>Human Expertise</strong>: Allow your sales team to
                      focus on complex issues that require empathy and critical
                      thinking.
                    </li>
                    <li>
                      <strong>Seamless Collaboration</strong>: Use AI to support
                      your sales team, not replace it.
                    </li>
                  </ul>
                </div>

                {/* Belief 4 */}
                <div className="bg-white rounded-lg shadow-lg p-6">
                  <h3 className="text-2xl font-bold mb-4 text-red-600">
                    4. Empowering Businesses to Lead the Change
                  </h3>
                  <p className="text-lg mb-4 text-gray-700">
                    In a rapidly evolving digital landscape, staying ahead is
                    crucial. Kimavi equips you with the tools to:
                  </p>
                  <ul className="list-disc list-inside text-lg space-y-2 text-gray-700">
                    <li>
                      <strong>Innovate Prospect Interaction</strong>: Stand out
                      with advanced AI capabilities.
                    </li>
                    <li>
                      <strong>Enhance Prospect Satisfaction</strong>: Deliver
                      fast, accurate, and personalized support.
                    </li>
                    <li>
                      <strong>Drive Growth</strong>: Convert more prospects and
                      retain customers through exceptional service.
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Get Started with Kimavi */}
            <section className="mb-12 m-1 p-1">
              <h2 className="text-4xl font-bold mb-8 text-center text-gray-800">
                Get Started with Kimavi
              </h2>
              <div className="space-y-8">
                {/* Sales Agent */}
                <div className="bg-gradient-to-r from-yellow-400 to-orange-500 rounded-lg shadow-lg p-6">
                  <h3 className="text-2xl font-bold mb-4 text-white">
                    Create Sales
                    <Link
                      to={{ pathname: "/chat" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-teal hover:underline"
                    >
                      {" "}
                      ChatGPT{" "}
                    </Link>
                    - Use our content creation tools to train the Sales Agent
                  </h3>
                  <ul className="list-disc list-inside text-lg space-y-2 text-white">
                    <li>
                      <strong>Engage Prospects</strong>: Interact with visitors
                      in real-time, answer queries, and collect leads.
                    </li>
                    <li>
                      <strong>Assist Prospects</strong>: Provide step-by-step
                      guides, screenshots, and videos directly through the Sales
                      Agent.
                    </li>
                    <li>
                      <strong>Multi-Platform Integration</strong>: Connect with
                      WhatsApp, Slack, and more to reach prospects wherever they
                      are.
                    </li>
                  </ul>
                </div>
                {/* How-To Guides */}
                <div className="bg-gradient-to-r from-pink-400 to-purple-500 rounded-lg shadow-lg p-6">
                  <h3 className="text-2xl font-bold mb-4 text-white">
                    Create How-To
                    <Link
                      to={{ pathname: "/guide" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-800 hover:underline"
                    >
                      {" "}
                      Guides{" "}
                    </Link>
                    - Train your Sales Agent with Quality How-To Data
                  </h3>
                  <ul className="list-disc list-inside text-lg space-y-2 text-white">
                    <li>
                      <strong>AI-Powered Extension</strong>: Quickly create
                      guides while browsing.
                    </li>
                    <li>
                      <strong>Visual Editing</strong>: Upload and edit images,
                      annotate, and customize your content.
                    </li>
                    <li>
                      <strong>Easy Publishing</strong>: Share guides in multiple
                      formats with just a few clicks.
                    </li>
                  </ul>
                </div>
                {/* Video Tutorials */}
                <div className="bg-gradient-to-r from-teal-400 to-blue-500 rounded-lg shadow-lg p-6">
                  <h3 className="text-2xl font-bold mb-4 text-white">
                    Create Video
                    <Link
                      to={{ pathname: "/ai" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-800 hover:underline"
                    >
                      {" "}
                      Tutorials{" "}
                    </Link>
                    - Train your Sales Agent with Quality Video and Audio Data
                  </h3>
                  <ul className="list-disc list-inside text-lg space-y-2 text-white">
                    <li>
                      <strong>Simple Script Creation</strong>: Use our AI to
                      draft video scripts in minutes.
                    </li>
                    <li>
                      <strong>Customization</strong>: Personalize backgrounds,
                      fonts, and themes to match your brand.
                    </li>
                    <li>
                      <strong>Multiple Formats</strong>: Publish videos in
                      landscape, portrait, or shorts for various platforms.
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Join the Sales Success Revolution */}
            <section className="mb-12 m-1 p-1">
              <div className="bg-gradient-to-r from-indigo-500 to-purple-600 rounded-lg shadow-lg p-8">
                <h2 className="text-4xl font-bold mb-8 text-center text-white">
                  Join the Sales Success Revolution
                </h2>

                <p className="text-lg mb-8 text-white text-center">
                  Embrace the future of sales success with tools designed to
                  simplify pre-sales and onboarding while amplifying results.
                  Whether you're a small business or a large enterprise, Kimavi
                  offers scalable solutions tailored to your needs.
                </p>
                <div className="flex flex-col items-center space-y-4">
                  <a
                    href="mailto:admin@kimavi.com"
                    className="bg-gray-900 text-cyan px-6 py-3 rounded-full hover:bg-blue-800 transform hover:scale-105 transition-transform"
                  >
                    Contact Us for a Personalized Demo
                  </a>
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </div>
  );
}

export default HomeLayout;
