import dedent from "dedent";

const knownQA = [
  {
    question: "What is Kimavi?",
    answer: dedent`
      Kimavi is a platform focused on driving Sales Success through its tools that help create engaging content. It offers features such as:
      
      1. **Video Tutorials**: Users can create video tutorials easily using the Video Script Creator.
      2. **How-To Guides**: The How-To Guide Creator, powered by an AI Browser Extension, allows for quick creation of guides and documents.
      3. **Sales Agent**: Users can train a ChatGPT on their product to engage prospects, collect leads, and assist customers with personalized support.
      
      By utilizing these tools, users can enhance their Sales Success and streamline content creation. 
      
      If you have more specific questions or need assistance with any feature, feel free to ask!
      `,
  },
  {
    question: "What is Kimavi's vision?",
    answer: dedent`
      Our vision is to empower businesses worldwide to achieve exceptional sales success through AI-enabled Sales Agents enriched with product demo videos and how-to guides, transforming every prospect interaction into a personalized, engaging, and impactful experience.
      `,
  },
  {
    question: "How can Kimavi help with sales success?",
    answer: dedent`
      Kimavi enhances sales success by providing tools that improve customer engagement across pre-sales, onboarding, and support. With features like a custom AI-powered Sales Agent, in-app guides, and video tutorials, businesses can:
      
      - **Engage Prospects**: Interact with visitors in real-time using the Sales Agent trained on your product.
      - **Assist Customers**: Provide step-by-step guides and videos directly through the Sales Agent or in-app, enhancing customer onboarding and support.
      - **Increase Conversion Rates**: By offering personalized and immediate assistance, you can improve lead generation and customer satisfaction, leading to higher sales success.
      `,
  },
  {
    question: "What are the key features of Kimavi's Sales Agent?",
    answer: dedent`
      Kimavi's Sales Agent offers several key features:
      
      1. **Customizable Sales Agent**: Tailor ChatGPT to align perfectly with your product and brand.
      2. **Seamless Integration**: Easily integrate into your website, mobile app, WhatsApp, Slack, or Zapier with minimal effort.
      3. **Advanced Analytics**: Gain insights into user interactions and improve engagement.
      4. **Multi-Platform Integration**: Connect with multiple platforms to reach prospects wherever they are.
      5. **Personalized Responses**: Train the Sales Agent using your own content, including videos and how-to guides, to provide accurate and helpful answers.
      `,
  },
  {
    question: "How do I create a Sales Agent with Kimavi?",
    answer: dedent`
      To create a Sales Agent with Kimavi:
      
      1. **Sign Up**: Register for an account on Kimavi and choose the Advanced AI Creator subscription plan.
      2. **Create Content**: Use Kimavi's tools to create high-quality, rich media content such as videos, how-to guides, and documents.
      3. **Train the Sales Agent**: Input your product information, FAQs, and the content you created to train the Sales Agent.
      4. **Customize Appearance**: Personalize the Sales Agent's look and feel to match your brand identity.
      5. **Integrate**: Deploy the Sales Agent on your website, mobile app, or integrate with platforms like WhatsApp and Slack.
      6. **Monitor and Improve**: Use the advanced analytics to track interactions and continuously improve the Sales Agent's performance.
      `,
  },
  {
    question: "What are the benefits of using Kimavi's How-To Guide Creator?",
    answer: dedent`
      Kimavi's How-To Guide Creator offers several benefits:
      
      - **AI-Powered Extension**: Quickly create guides while browsing using the AI-powered browser extension.
      - **Visual Editing**: Upload and edit images, annotate, and customize your content with ease.
      - **Easy Publishing**: Share guides in multiple formats with just a few clicks.
      - **Train Your Sales Agent**: Use the high-quality guides you create to train your Sales Agent, enhancing its effectiveness.
      - **Improve Onboarding**: Provide step-by-step instructions to assist customers and prospects, improving their experience with your product.
      `,
  },
  {
    question: "How can I create compelling product demo videos with Kimavi?",
    answer: dedent`
      Creating compelling product demo videos with Kimavi is simple:
      
      1. **Use the AI Script Generator**: Quickly generate video scripts using Kimavi's AI-powered tool.
      2. **Customize Your Video**: Personalize backgrounds, fonts, and themes to match your brand.
      3. **Add Voiceover**: Use AI-generated voices or record your own voice for narration.
      4. **Publish in Multiple Formats**: Create videos in landscape, portrait, or shorts formats suitable for various platforms.
      5. **Share and Train**: Use your videos to engage prospects, share on social media, or train your Sales Agent for better customer interactions.
      `,
  },
  {
    question: "What are Kimavi's beliefs and commitments?",
    answer: dedent`
      Kimavi is built on four core beliefs and commitments:
      
      1. **Sales Success is Our Priority**: We believe businesses thrive when sales succeed. Our tools help you engage prospects, assist customers, and drive sales success.
      2. **AI is Transforming Sales Success**: We believe artificial intelligence is revolutionizing how businesses interact with prospects, enabling instant answers and personalized content.
      3. **Human + AI Equals Unmatched Sales**: While AI enhances efficiency, the human touch remains invaluable. We ensure a harmonious blend of both.
      4. **Empowering Businesses to Lead the Change**: We equip you with tools to innovate prospect interaction, enhance satisfaction, and drive growth.
      `,
  },
  {
    question: "How can I get started with Kimavi?",
    answer: dedent`
      To get started with Kimavi:
      
      1. **Sign Up**: Register for a free account to explore the platform.
      2. **Choose a Subscription Plan**: Select a plan that suits your needs, such as the Basic AI Creator or Advanced AI Creator for full features.
      3. **Create Content**: Start creating how-to guides, video tutorials, or train your Sales Agent using our tools.
      4. **Integrate and Share**: Deploy your content on your website, mobile app, or share on social media platforms.
      5. **Contact Us**: For personalized demos or assistance, feel free to contact us at admin@kimavi.com.
      `,
  },
  {
    question:
      "Does Kimavi integrate with other platforms like WhatsApp and Slack?",
    answer: dedent`
      Yes, Kimavi's Sales Agent can integrate with various platforms including WhatsApp, Slack, and more. This allows you to reach prospects and customers on the platforms they use most. To integrate:
      
      1. **Access Integration Settings**: In the Sales Agent section, navigate to Integrations.
      2. **Connect to Platforms**: Follow the prompts to link your accounts, such as WhatsApp Business or Slack workspace.
      3. **Customize and Test**: Configure messaging settings, personalize interactions, and test the Sales Agent to ensure functionality.
      `,
  },
  {
    question: "How can Kimavi help in customer onboarding and support?",
    answer: dedent`
      Kimavi assists in customer onboarding and support by providing:
      
      - **Sales Agent**: Offer instant, personalized assistance to customers through a Sales Agent trained on your product.
      - **How-To Guides**: Create step-by-step guides to help customers navigate your product features.
      - **Video Tutorials**: Develop engaging videos to demonstrate product usage and best practices.
      - **In-App Integration**: Embed guides and videos directly into your application for seamless customer experiences.
      `,
  },
  {
    question: "What makes Kimavi different from its competitors?",
    answer: dedent`
      Kimavi stands out from its competitors by offering a comprehensive suite of tools that combine AI-powered Sales Agents with rich media content creation. Our platform allows you to:
      
      - **Train ChatGPT on Your Own Content**: Enhance your Sales Agent's effectiveness by training it with your own videos, guides, and documents.
      - **Create High-Quality Content Easily**: Use our AI-powered tools to create engaging how-to guides and video tutorials in minutes.
      - **Seamless Integration**: Easily integrate the Sales Agent and content into your website, mobile app, and other platforms.
      - **Advanced Analytics**: Gain deep insights into customer interactions to continuously improve your sales and support strategies.
      `,
  },
  {
    question:
      "Can you tell me more about Kimavi's experience and customer base?",
    answer: dedent`
      Kimavi has extensive experience in deploying in-app and on-premises how-to guide software in organizations ranging from UBS, AbbVie, and Westpac Bank to the World Bank. Our team has deployed extensions on all employee machines at major financial institutions, passing all security and privacy checks. Additionally:
      
      - **23,000+ Subscribers**: We have a rapidly growing community of loyal subscribers.
      - **10 Million+ Views**: Our videos and guides have garnered over 10 million views on YouTube and other platforms.
      - **Enterprise-Level Expertise**: We bring enterprise-level experience to help businesses of all sizes achieve sales success.
      `,
  },
  {
    question: "Ready to create your Sales Agent?",
    answer: dedent`
      Absolutely! You can start by signing up on our platform and choosing the Advanced AI Creator plan, which includes the Sales Agent feature. Create, train, personalize, and deploy your Sales Agent in minutes to engage prospects, collect leads, and provide personalized support.
      `,
  },
  {
    question: "How do I use the AI Script Generator?",
    answer: dedent`
      To use the AI Script Generator in Kimavi:
      
      1. **Access the AI Script Generator**: Navigate to the Video Tutorials section and select the AI Script Generator.
      2. **Input Your Topic**: Enter the topic or key points you want to cover in your video.
      3. **Generate Script**: Let the AI generate a video script for you in seconds.
      4. **Customize the Script**: Review and edit the script as needed to ensure it aligns with your message.
      5. **Proceed to Video Creation**: Use the script to create your video tutorial, adding visuals, voiceovers, and customizations.
      `,
  },
  {
    question: "How can I install the Kimavi browser extension?",
    answer: dedent`
      To install the Kimavi browser extension:
      
      1. **Visit the Chrome Web Store**: Go to the extension page at [Chrome Web Store](https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne).
      2. **Click 'Add to Chrome'**: Install the extension in your browser.
      3. **Create How-To Guides**: Start creating guides directly from your browser with the AI-powered extension.
      4. **Login to Kimavi**: Ensure you're logged in to your Kimavi account to save and manage your guides.
      `,
  },
  {
    question: "How many subscribers and views does Kimavi have?",
    answer: dedent`
      Kimavi has over 23,000 subscribers and more than 10 million views on our videos and guides across platforms like YouTube, Facebook, Instagram, and Twitter. Our content helps users worldwide learn about various products and technologies.
      `,
  },
  {
    question: "How does Kimavi ensure data security and privacy?",
    answer: dedent`
      Kimavi is committed to ensuring data security and privacy. We have deployed extensions and solutions at major financial institutions, passing all security and privacy checks. We adhere to strict data protection policies and comply with relevant regulations to safeguard your information.
      `,
  },
  {
    question: "How to create Video Tutorials?",
    answer: "https://www.youtube.com/watch?v=2GIumvSUO_Q",
  },
  {
    question: "How to create How-To Tutorials?",
    answer: "https://www.youtube.com/watch?v=HJ27CY8T_nY",
  },
  {
    question: "Can you give me an example of a video that a user has created?",
    answer: "https://www.youtube.com/watch?v=024EELV89SA",
  },
  {
    question: "What is blog to video? Can you give me an example?",
    answer: "https://www.youtube.com/watch?v=sjFTTq3g5vU",
  },
  {
    question: "Can you create podcasts? Give me an example?",
    answer: "https://www.youtube.com/watch?v=3iptXOvnN4g",
  },
  {
    question: "Give me an example of a How-To video created by the extension?",
    answer: "https://www.youtube.com/shorts/7ijhV7yfkJQ",
  },
  {
    question: "Who are your competitors?",
    answer:
      "Try us today and experience the difference! Of course, we have fantastic competitors, and we continuously learn from each other. But jokes aside, give us a try and see what sets us apart!",
  },
  {
    question: "How do I create a Sales Agent for my products?",
    answer: dedent`
      To create a Sales Agent for your product using Kimavi, you can follow these general steps:
      
      1. **Sign Up for a Subscription**: Choose a plan that includes the Sales Agent feature. The Advanced AI Creator tier offers full access to this functionality.
      
      2. **Train Your ChatGPT**:
         - Go to the Sales Agent section on the Kimavi platform.
         - Input relevant information about your product, including features, FAQs, and any other useful data that can help the Sales Agent engage prospects and assist customers.
      
      3. **Integrate with Your Platform**:
         - Use the provided embed code to add the Sales Agent to your website or mobile app.
         - Connect the Sales Agent to messaging platforms like WhatsApp and Slack for broader engagement.
      
      4. **Customize Appearance**: Personalize the Sales Agent’s appearance to match your brand identity, including colors, fonts, and logos.
      
      5. **Test Your Sales Agent**: Interact with the Sales Agent to ensure it provides accurate responses and engages users effectively.
      
      6. **Monitor and Improve**: Use the analytics tools available to track interactions, gather feedback, and continuously improve the Sales Agent's performance.
      
      If you need detailed guidance on any specific step or feature, feel free to ask!
      `,
  },
  {
    question: "What subscription plans does Kimavi offer?",
    answer: dedent`
      Kimavi offers three subscription tiers to cater to different user needs:
      
      1. **Free Subscription**
         - **Preview**: Get started without a credit card.
         - **Create How-To Guides with Limits**: Create your first How-To Guide via the extension.
         - **Create Video Tutorials with Limits**: Create your first Video Tutorial with limited features.
         - **Learn**: Explore our Videos and Guides.
         - **Community Support**: Access to community forums.
         - **Analytics**: Not available.
         - **Support**: Via Video Tutorials.
      
      2. **Basic AI Creator - $10 a month**
         - **Unlock Basic AI Features** (Guides + Videos).
         - **Create How-To Guides**: AI-assisted How-To Guides with standard editing tools.
         - **Create Video Tutorials**: AI-assisted Video Creation with standard editing tools.
         - **Basic Audio**: Access AI-generated voices.
         - **Learn**: Full access to courses.
         - **Basic Analytics**: Track views and engagement on your content.
         - **Support**: Via Email.
         - **Create Sales Agent**: Upgrade to Advanced AI Creator for this feature.
      
      3. **Advanced AI Creator - $20 a month**
         - **Full Access to Premium AI Features** (Guides + Videos + ChatGPT).
         - **Create How-To Guides**: Full AI-assisted How-To Guides with advanced editing tools.
         - **Create Video Tutorials**: Full AI-assisted Video Creation with advanced editing tools.
         - **Create Sales Agent**: Personalized ChatGPT for your product—deploy on your website.
         - **Premium Audio**: Access AI-generated premium voices.
         - **Advanced Analytics**: In-depth Analytics and reporting.
         - **Customize**: Advanced template customization.
         - **On-Prem Hosting**: Contact us for On-Prem hosted solutions.
         - **SSO Integration**: Support for Single Sign-On.
         - **Priority Support**: Via Email, Phone, and Zoom.
      `,
  },
  {
    question: "How do I use the Image Editor in Kimavi?",
    answer: dedent`
      To use the Image Editor in Kimavi, follow these steps:
      
      1. **Access the Image Editor**: Within the Video Script Creator or How-To Guide Creator, select the option to upload or edit images.
      
      2. **Upload an Image**: Click on the "Upload" button to add an image from your device.
      
      3. **Edit the Image**:
         - **Draw**: Use the drawing tools to annotate or highlight parts of the image.
         - **Blur**: Apply blurring to sensitive information or areas you wish to obscure.
         - **Crop**: Trim the image to focus on specific content.
         - **Position**: Adjust the placement of the image within your content.
      
      4. **Save Changes**: Once you're satisfied with the edits, save the image to include it in your tutorial or guide.
      
      5. **Preview**: Review how the edited image appears in your content to ensure it meets your requirements.
      
      If you need further assistance with image editing features, feel free to ask!
      `,
  },
  {
    question: "How can I publish videos in multiple formats?",
    answer: dedent`
      Publishing videos in multiple formats with Kimavi is straightforward:
      
      1. **Create Your Video**: Use the Video Script Creator to develop your tutorial content.
      
      2. **Customize Formats**:
         - Before publishing, select the formats you wish to generate: **Landscape**, **Portrait**, and **Shorts**.
         - Adjust any format-specific settings if needed.
      
      3. **Review Content and Audio**:
         - Ensure that your script and audio are finalized.
         - Preview the video in each format to check for any layout or content adjustments.
      
      4. **Publish**:
         - Click the "Publish" button.
         - Kimavi will generate the videos in the selected formats.
      
      5. **Access Your Videos**:
         - Once processing is complete, you can download or share the videos directly from the platform.
         - Utilize the provided links or embed codes to share on various platforms.
      
      By publishing in multiple formats, you can reach a wider audience across different platforms and devices.
      `,
  },
  {
    question: "How do I customize the appearance of my video tutorials?",
    answer: dedent`
      Customizing the appearance of your video tutorials in Kimavi enhances brand identity:
      
      1. **Access Customization Settings**:
         - In the Video Script Creator, navigate to the **Customization** or **Settings** section.
      
      2. **Backgrounds**:
         - Choose from predefined backgrounds or upload your own images.
         - Adjust background colors and patterns to match your branding.
      
      3. **Fonts**:
         - Select from various font styles for your text.
         - Ensure readability and consistency with your brand guidelines.
      
      4. **Footers**:
         - Add footer text or images, such as logos or disclaimers.
         - Customize the footer's appearance and position.
      
      5. **Themes and Templates**:
         - Apply themes that align with your content's tone.
         - Use templates to maintain consistency across multiple videos.
      
      6. **Preview Changes**:
         - Review how the customizations affect your video.
         - Make adjustments as necessary before publishing.
      
      Personalizing your tutorials helps in creating a professional and cohesive experience for your audience.
      `,
  },
  {
    question: "How do I connect my Sales Agent to WhatsApp and Slack?",
    answer: dedent`
      Connecting your Kimavi Sales Agent to messaging platforms extends its reach:
      
      1. **Access Integration Settings**:
         - Go to the **Integrations** section within the Sales Agent area.
      
      2. **Connect to WhatsApp**:
         - Follow the prompts to link your WhatsApp Business account.
         - Configure messaging settings and permissions.
         - Verify the connection by sending a test message.
      
      3. **Connect to Slack**:
         - Authorize Kimavi to access your Slack workspace.
         - Select the channels where the Sales Agent should be active.
         - Customize bot responses and commands if necessary.
      
      4. **Test the Integrations**:
         - Interact with the Sales Agent on both platforms to ensure functionality.
         - Check for any issues with message formatting or delays.
      
      5. **Monitor Interactions**:
         - Use Kimavi's analytics to track engagement on these platforms.
         - Adjust settings based on user feedback and interaction data.
      
      If you encounter any difficulties during the integration process, don't hesitate to reach out for support.
      `,
  },
  {
    question: "Can I use my own voice in the video tutorials?",
    answer: dedent`
      Yes, you can use your own voice in Kimavi's video tutorials:
      
      1. **Record Your Voice**:
         - Within the Video Script Creator, navigate to the **Audio** section.
         - Select the option to **Record Voice**.
         - Use your device's microphone to record narration for each slide or the entire video.
      
      2. **Upload Pre-Recorded Audio**:
         - If you have existing audio files, you can upload them.
         - Ensure the audio aligns with your video content.
      
      3. **Edit Audio**:
         - Trim or adjust the audio clips as needed.
         - Synchronize the audio with the corresponding slides or visuals.
      
      4. **Preview**:
         - Play back the video to ensure audio quality and synchronization.
         - Make any necessary adjustments before publishing.
      
      Using your own voice adds a personal touch and can enhance the connection with your audience.
      `,
  },
  {
    question: "What advanced features are available to enhance my content?",
    answer: dedent`
      Kimavi offers several advanced features to enrich your tutorials and guides:
      
      1. **LaTeX Formulas**:
         - Include mathematical equations and formulas using LaTeX syntax.
         - Ideal for educational content requiring precise mathematical representations.
      
      2. **Emojis and Icons**:
         - Add visual elements like emojis to make content more engaging.
         - Use icons to highlight important information.
      
      3. **Code Snippets**:
         - Insert formatted code blocks for programming tutorials.
         - Supports syntax highlighting for various programming languages.
      
      4. **Multiple Languages**:
         - Create content in different languages to reach a global audience.
         - Utilize language-specific fonts and formatting.
      
      5. **Interactive Elements**:
         - Include quizzes or interactive questions within your content.
         - Encourage audience participation and retention.
      
      6. **Advanced Animations**:
         - Apply custom animations to text and images.
         - Control timing and transitions for a dynamic presentation.
      
      Exploring these features can significantly enhance the quality and effectiveness of your content.
      `,
  },
  {
    question: "If Kimavi were a superhero, what powers would it have?",
    answer: dedent`
         If Kimavi were a superhero, its powers would include:
    
         - **Content Creation Beam**: Instantly generate engaging videos and guides with a single beam!
         - **AI Sidekick**: A trusty AI companion that never runs out of ideas or energy.
         - **Analytics X-Ray Vision**: See through data to understand exactly what your customers need.
         - **Customization Shield**: Protect and personalize your content with unbeatable style.
         
         Together, these powers ensure your content always saves the day!
         `,
  },
  {
    question: "What’s Kimavi’s favorite type of coffee?",
    answer:
      "A latte with a shot of AI! It keeps our content creation energized and smooth ☕🤖",
  },
  {
    question: "Can Kimavi dance? If yes, what’s its signature move?",
    answer: dedent`
         Absolutely! Kimavi's signature dance move is the **"Content Shuffle"**:
         
         1. **Slide Step**: Moving seamlessly between video tutorials and how-to guides.
         2. **AI Twist**: Spinning creative ideas with a touch of AI magic.
         3. **Engagement Spin**: Twirling to keep the audience captivated and entertained.
         
         Whether it's a quick tutorial or a detailed guide, Kimavi dances its way to delivering top-notch content!
         `,
  },
  {
    question: "If Kimavi could have a pet, what would it be?",
    answer:
      "A digital owl named 'ScriptWise' 🦉—wise in creating scripts and always watching over your content!",
  },
  {
    question: "What song does Kimavi sing when no one is watching?",
    answer:
      "🎶 'Don't Stop Believin' by Journey' – because with Kimavi, your customers will always reach new heights! 🎶",
  },
];

export default knownQA;
