export const FeaturedMain = [
  {
    isPublic: true,
    createdTime: 1710269461000,
    guideTitle:
      "How to blend two videos at Sora and achieve amazing results, let's make our butterfly fly into space",
    recordingSessionId: "recording_1734045269550_c02b91aba9b5d",
    guideLastSavedTimeStamp: 171028959999,
    tags: ["Sora"],
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
  },
  {
    isPublic: true,
    createdTime: 1710269461000,
    guideTitle:
      "Create Amazing Videos! How to use the StoryBoard feature at Sora?",
    recordingSessionId: "recording_1734023508031_e1034bbd9f7c3",
    guideLastSavedTimeStamp: 171028959999,
    tags: ["Sora"],
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
  },
  {
    isPublic: true,
    createdTime: 1710269461000,
    guideTitle:
      "How to Re Mix an Existing Video at Sora, Blending different Visual elements",
    recordingSessionId: "recording_1733941937710_96d592f24e51",
    guideLastSavedTimeStamp: 171027859999,
    tags: ["Sora"],
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
  },
  {
    isPublic: true,
    createdTime: 1710269461000,
    guideTitle:
      "Image to Video, How to create your first AI Video with an image upload at Sora",
    recordingSessionId: "recording_1733939862039_b9047ea2f7fcc",
    guideLastSavedTimeStamp: 171027859999,
    tags: ["Sora"],
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
  },

  {
    createdTime: 1709971042833,
    tags: ["kimavi"],
    guideTitle:
      "How to Guide - How to use Kimavi Chrome Browser Extension - For Creating How To Videos and GIF with Generative AI",
    guideLastSavedTimeStamp: 1710132164956,
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
    recordingSessionId: "recording_1709971042833_7218fbbdad965",
    isPublic: true,
  },
];
