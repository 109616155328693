//App.js

import { HelmetProvider } from "react-helmet-async";
import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
  useRef,
  useContext,
} from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Login from "./login";
import { FaSpinner } from "react-icons/fa";
import { auth } from "./firebase";
import { signOut } from "firebase/auth";
import { Helmet } from "react-helmet";
import CatchAll from "./CatchAll";
import { AuthContext } from "./AuthProvider";
import {
  useParams,
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import HomeLayout from "./HomeLayout";
import GoRedirect from "./GoRedirect"; // Import the GoRedirect component
import "./App.css";
import AnalyticsLogger from "./AnalyticsLogger";

// Lazy load components
const Mission = lazy(() => import("./mission"));
const QRCodeGenerator = lazy(() => import("./QRCodeGenerator"));
const ThumbnailCreator = lazy(() => import("./ThumbnailCreator"));
const Admin = lazy(() => import("./AdminPage"));
const Food = lazy(() => import("./Food"));
const GoForm = lazy(() => import("./components/GoForm"));
const GoList = lazy(() => import("./components/GoList"));
const Go = lazy(() => import("./Go"));
const OKR = lazy(() => import("./OKR"));
const VideoCreate = lazy(() => import("./VideoCreate"));
const Guide = lazy(() => import("./Guide"));
const GuideMD = lazy(() => import("./GuideMD"));
const Examples = lazy(() => import("./example"));
const GuideMDServer = lazy(() => import("./GuideMDServer"));
const MyGuides = lazy(() => import("./MyGuide"));
const MyVideo = lazy(() => import("./MyVideo"));
const Course = lazy(() => import("./Course"));
const My = lazy(() => import("./My"));
const TutorialCreate = lazy(() => import("./TutorialCreate"));
const LectureCreate = lazy(() => import("./LectureCreate"));
const Quiz = lazy(() => import("./QuizCreate"));
const Family = lazy(() => import("./Family"));
const ShowPublicQuiz = lazy(() => import("./components/quiz/showPublicQuiz"));
const MyQuiz = lazy(() => import("./components/quiz/myQuiz"));
const DreamLayout = lazy(() => import("./DreamLayout"));
const SubscribePage = lazy(() => import("./SubscribePage"));
const PrivacyLayout = lazy(() => import("./PrivacyLayout"));
const ShowVideo = lazy(() => import("./components/ShowGuide"));
const TextToAudioConverter = lazy(() => import("./TextToAudioConverter"));
const Tags = lazy(() => import("./tags"));
const ChatGPT = lazy(() => import("./chat"));
const ChatGPTVieu = lazy(() => import("./chat-vieu"));
const ChatGPTPDX = lazy(() => import("./chat-pdx"));
const ChatGPTOodle = lazy(() => import("./chat-oodle"));
const ChatBot = lazy(() => import("./chatbot"));
const ChatAdmin = lazy(() => import("./chatadmin"));
const ChatBotIframePage = lazy(() => import("./chatbotiframe"));
const ChatBotIframeVieuPage = lazy(() => import("./chatbotiframe-vieu"));
const ChatBotIframeOodlePage = lazy(() => import("./chatbotiframe-oodle"));
const ChatBotIframePDXPage = lazy(() => import("./chatbotiframe-pdx"));
const ChatBotIframeEmbed = lazy(() => import("./ChatBotIframeEmbed"));

const BlogToVideo = lazy(() => import("./BlogToVideo"));
const ChatbotCreator = lazy(() => import("./chat/ChatbotCreator"));
const VideoAll = lazy(() => import("./chat/videoAll"));

function AppContent() {
  const [showLogin, setShowLogin] = useState(false);
  const [loginNotification, setLoginNotification] = useState(false);
  const [notificationShown, setNotificationShown] = useState(false);
  const [logoutNotification, setLogoutNotification] = useState(false);

  const renderCount = useRef(0);
  const authContextValue = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const location = useLocation(); // get the current location
  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  // Define paths to hide the footer
  const hideFooterPaths = [
    "/ai",
    "/guide",
    "/video",
    "/myvideo",
    "/tc",
    "/logo",
    "/banner",
    "/aiserver",
    "/qr",
    "/college",
    "/myguide",
    "/audio",
    "/example",
    "/help",
    "/training",
    "/chatbot",
    "/chatadmin",
    "/chat",
    "/view",
    "/agent",
    "/chat-vieu",
    "/chat-oodle",
    "/chat-pdx",
    "/tag",
    "/iframe-embed",
    "/iframe-embed-oodle",
    "/iframe-embed-pdx",
    "/blog-to-video",
    "/videoall",
    "/chat-id",
    "/create-chatbot",
  ];

  // Define isVideoEmbed and isPup outside of useEffect for global use
  const query = new URLSearchParams(window.location.search);
  const isVideoEmbed =
    location.pathname.includes("/video") && query.get("embed") === "true";
  let isIFrameEmbed;
  isIFrameEmbed =
    location.pathname.includes("/iframe-embed") ||
    location.pathname.includes("/iframe-embed-vieu") ||
    location.pathname.includes("/iframe-embed-oodle");

  const isPup = query.get("pup") === "true";

  // Check if the path or query parameter should hide the footer
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    const shouldHide =
      isPup ||
      hideFooterPaths.some((path) => location.pathname.startsWith(path));

    setShouldHideFooter(shouldHide);
    // console.log("shouldHideFooter", shouldHide);
  }, [location]); // Run this effect whenever location changes

  // Show login notification
  useEffect(() => {
    if (user && !notificationShown) {
      setLoginNotification(true);
      setNotificationShown(true);
      setTimeout(() => {
        setLoginNotification(false);
      }, 3000); // Hide after 3 seconds
    }
  }, [user, notificationShown]);

  // Handle logout and show notification
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setLogoutNotification(true);
        setTimeout(() => {
          setLogoutNotification(false);
          window.location.reload(); // Refresh the page after signout
        }, 3000); // Notification duration before reload
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const closeLogin = () => {
    setShowLogin(false);
  };

  function generateTitleAndDescriptionFromPath(path) {
    const pathSegments = path.split("/").filter((segment) => segment !== "");

    // Adjusted function to exclude only unwanted patterns of short length
    const isValidSegment = (segment) => {
      // Exclude segments that are only letters followed by numbers, or numbers followed by letters, and are shorter than or equal to 10 characters
      const regex = /^[-_]?[a-zA-Z]+?\d+[-_]?$|^[-_]?\d+?[a-zA-Z]+[-_]?$/i;
      return !(regex.test(segment) && segment.length <= 10);
    };

    // Clean up segments by removing unwanted patterns at the end
    const cleanSegment = (segment) => {
      // Remove trailing patterns like '-abc123' or '-123abc'
      return segment.replace(/[-_][a-zA-Z]*\d{3,}$|[-_]\d{3,}[a-zA-Z]*$/i, "");
    };

    const filteredSegments = pathSegments.filter(isValidSegment);
    const cleanedSegments = filteredSegments.map(cleanSegment);

    const capitalizedSegments = cleanedSegments.map((segment) =>
      segment
        .split("-")
        .map((word) => capitalize(word))
        .join(" ")
    );

    if (capitalizedSegments.length > 0) {
      const newTitle = `Kimavi: ${capitalizedSegments.join(", ")}`;
      const newDescription = `Explore Chat, Videos and Guides with Kimavi on ${capitalizedSegments.join(
        ", "
      )}`;
      return { title: newTitle, description: newDescription };
    } else {
      // Return default title and description
      return {
        title: "Kimavi: Chat, Videos and Guided Automation",
        description:
          "Kimavi's AI Agent can not only answer questions, show custom videos, but it can also give demos and complete actions on behalf of the user!",
      };
    }
  }

  function capitalize(text) {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  function DynamicMetaTags() {
    const location = useLocation();
    const [title, setTitle] = useState(
      "Kimavi: Chat, Videos and Guided Automation "
    );
    const [description, setDescription] = useState(
      "Kimavi's AI Agent can not only answer questions, show custom videos, but it can also give demos and complete actions on behalf of the user!"
    );

    useEffect(() => {
      const path = location.pathname;
      let newTitle;
      let newDescription;

      // Handle dynamic routes
      if (path.match(/^\/quiz\/\d+/)) {
        newTitle = "Quiz - Kimavi";
        newDescription = "Take a quiz on Kimavi.";
      } else if (path.match(/^\/family\/[^/]+/)) {
        const familyName = path.split("/")[2];
        newTitle = `Family Tree of ${capitalize(familyName)} - Kimavi`;
        newDescription = `Discover the Family Tree of ${capitalize(
          familyName
        )}. Explore your heritage and uncover the stories of your ancestors.`;
      } else if (path.match(/^\/go\/[^/]+/)) {
        newTitle = "Redirecting... - Kimavi";
        newDescription = "Redirecting to the destination.";
      } else if (path.match(/^\/tag\/[^/]+/)) {
        const tagName = path.split("/")[2];
        newTitle = `Tag: ${capitalize(tagName)} - Kimavi`;
        newDescription = `Explore content tagged with ${capitalize(tagName)}.`;
      } else if (path.match(/^\/\d{4,}$/)) {
        newTitle = "Quiz - Kimavi";
        newDescription = "Take a quiz on Kimavi.";
      } else {
        // Static routes
        switch (path) {
          case "/":
            newTitle = "Kimavi: Chat, Video & Guided Automation";
            newDescription =
              "Chat, Videos and Guided Automation. Kimavi's AI Agent can not only answer questions, show custom videos, but it can also give demos and complete actions on behalf of the user!";
            break;
          case "/privacy-security-cookie":
            newTitle = "Privacy, Security, and Cookies - Kimavi";
            newDescription =
              "Learn about our policies on privacy, security, and cookies.";
            break;
          case "/guide":
            newTitle = "Create How-To Guides - Kimavi";
            newDescription =
              "Develop step-by-step How-To guides to Drive Sales.";
            break;
          case "/guide-md":
            newTitle = "Markdown Guide Creation - Kimavi";
            newDescription = "Use Markdown to create impactful guides.";
            break;
          case "/create":
            newTitle = "Start Creating - Kimavi";
            newDescription =
              "Begin creating guides, videos, and chatbots to promote AI features.";
            break;
          case "/blog-to-video":
            newTitle = "Blog To Video - Kimavi";
            newDescription = "Easily convert blogs into Videos - Kimavi.";
            break;
          case "/myguide":
            newTitle = "My Guides - Kimavi";
            newDescription = "View and manage your guides.";
            break;
          case "/myvideo":
            newTitle = "My Videos - Kimavi";
            newDescription = "View and manage your videos.";
            break;
          case "/video":
          case "/view":
            newTitle = "Create Videos - Kimavi";
            newDescription = "Produce video.";
            break;
          case "/course":
            newTitle = "Develop Courses - Kimavi";
            newDescription =
              "Create courses to educate and Drive Sales Success.";
            break;
          case "/quiz":
            newTitle = "Create Quizzes - Kimavi";
            newDescription = "Design quizzes to enhance AI learning.";
            break;
          case "/login":
            newTitle = "Login - Kimavi";
            newDescription =
              "Access your account to create Sales Success content.";
            break;
          case "/tutorial":
            newTitle = "Create Tutorials - Kimavi";
            newDescription = "Build tutorials to facilitate Sales Success.";
            break;
          case "/lecture":
            newTitle = "Create Lectures - Kimavi";
            newDescription = "Craft lectures to promote AI understanding.";
            break;
          case "/subscribe":
            newTitle = "Subscribe - Kimavi";
            newDescription =
              "Subscribe to access AI tools for creating adoption content.";
            break;
          case "/my":
            newTitle = "My Account - Kimavi";
            newDescription = "Manage your profile and creations.";
            break;
          case "/myquiz":
            newTitle = "My Quizzes - Kimavi";
            newDescription = "View your quiz history.";
            break;
          case "/family":
            newTitle = "Family Tree - Kimavi";
            newDescription = "Explore family trees.";
            break;
          case "/privacy":
            newTitle = "Privacy Policy - Kimavi";
            newDescription = "Read our privacy policy.";
            break;
          case "/security":
            newTitle = "Security Policy - Kimavi";
            newDescription = "Read our security policy.";
            break;
          case "/cookie":
            newTitle = "Cookie Policy - Kimavi";
            newDescription = "Read our cookie policy.";
            break;
          case "/qr":
            newTitle = "QR Code Generator - Kimavi";
            newDescription = "Generate QR codes effortlessly.";
            break;
          case "/tc":
            newTitle = "Thumbnail Creator - Kimavi";
            newDescription = "Create thumbnails easily.";
            break;
          case "/logo":
            newTitle = "Logo Creator - Kimavi";
            newDescription = "Design logos effortlessly.";
            break;
          case "/food":
            newTitle = "Food - Kimavi";
            newDescription = "Explore food-related content.";
            break;
          case "/admin":
            newTitle = "Admin - Kimavi";
            newDescription = "Administrative panel.";
            break;
          case "/create-video-with-ai":
            newTitle = "Create Video with AI - Kimavi";
            newDescription = "Create videos using AI technology.";
            break;
          case "/ai":
            newTitle = "Create Video with AI - Kimavi";
            newDescription =
              "Discover our AI capabilities for Video Tutorials - Create Now!";
            break;
          case "/banner":
            newTitle = "Banner Creator - Kimavi";
            newDescription = "Design banners easily.";
            break;
          case "/audio":
            newTitle = "Text to Audio Converter - Kimavi";
            newDescription = "Convert text to audio seamlessly.";
            break;
          case "/example":
            newTitle = "Examples - Kimavi";
            newDescription = "Browse through examples.";
            break;
          case "/training":
            newTitle = "Training - Kimavi";
            newDescription = "Access training resources.";
            break;
          case "/tags":
            newTitle = "Tags - Kimavi";
            newDescription = "Explore content by tags.";
            break;
          case "/chat-vieu":
            newTitle = "Try Vieu - Chatbot";
            newDescription =
              " AI-powered tools to enhance B2B sales through warm introductions";
            break;
          case "/chat-oodle":
            newTitle = "Try Oodle - Chatbot";
            newDescription =
              "Fully managed serverless metrics observability platform ";
            break;
          case "/chat":
            newTitle = "Create Sales Agent - Kimavi";
            newDescription = "Build Sales Agents trained on your products.";
            break;
          case "/chatbot":
            newTitle = "Interact with Sales Agent - Kimavi";
            newDescription =
              "Engage with chatbots to learn and Drive Sales Success.";
            break;
          case "/chatbot-id":
            newTitle = "Interact with The ChatBot";
            newDescription =
              "Engage with chatbots to learn and Drive Sales Success.";
            break;
          case "/chatadmin":
            newTitle = "Chatbots - Admin";
            newDescription =
              "Engage with chatbots to learn and Drive Sales Success.";
            break;
          default:
            // If no match, leave newTitle and newDescription undefined
            break;
        }
      }

      if (!newTitle || !newDescription) {
        // If newTitle or newDescription not set, generate them based on URL path
        const { title: generatedTitle, description: generatedDescription } =
          generateTitleAndDescriptionFromPath(path);
        newTitle = generatedTitle;
        newDescription = generatedDescription;
      }

      setTitle(newTitle);
      setDescription(newDescription);
    }, [location]);

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="https://www.kimavi.com/hc.jpg" />
        <meta
          property="og:url"
          content={`https://www.kimavi.com${location.pathname}`}
        />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Kimavi3" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content="https://www.kimavi.com/images/logo.png"
        />
      </Helmet>
    );
  }

  function capitalize(text) {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  if (!authContextValue) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <FaSpinner icon="spinner" className="animate-spin" size={50} />
      </div>
    );
  }

  function QuizRoute() {
    let { quizId } = useParams();
    if (quizId && quizId.match(/^\d{4,}$/)) {
      return <ShowPublicQuiz />;
    } else {
      return <CatchAll />;
    }
  }

  return (
    <>
      <DynamicMetaTags />
      <AnalyticsLogger />
      {!isVideoEmbed && !isPup && !isIFrameEmbed && (
        <Navbar onLoginClick={handleLoginClick} handleSignOut={handleSignOut} />
      )}
      {showLogin && <Login closeLogin={() => setShowLogin(false)} />}
      {logoutNotification && (
        <div className="fixed top-0 right-0 bg-green-500 text-white px-4 py-2 rounded">
          Logout successful
        </div>
      )}
      <div className="flex-grow w-full h-full">
        <div className="w-full h-full flex-grow">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {/* Define your routes */}
              <Route
                path="/privacy-security-cookie"
                element={<PrivacyLayout />}
              />
              <Route exact path="/" element={<HomeLayout />} />
              <Route path="/agent/:botId" element={<ChatBotIframeEmbed />} />
              <Route path="/chat-id/:botId" element={<ChatBotIframeEmbed />} />
              <Route path="/:quizId" element={<QuizRoute />} />
              <Route path="/guide" element={<Guide />} />
              <Route path="/guide-md" element={<GuideMD />} />
              <Route path="/create" element={<Guide />} />
              <Route path="/videoAll" element={<VideoAll />} />
              <Route path="/myguide" element={<MyGuides />} />
              <Route path="/myvideo" element={<VideoAll />} />
              <Route path="/video" element={<ShowVideo />} />
              <Route path="/view" element={<ShowVideo />} />
              <Route path="/course" element={<Course />} />
              <Route path="/quiz" element={<Quiz />} />
              <Route path="/login" element={<Login />} />
              <Route path="/quiz/:quizId" element={<ShowPublicQuiz />} />
              <Route path="/tutorial" element={<TutorialCreate />} />
              <Route path="/lecture" element={<LectureCreate />} />
              <Route path="/go/:name" element={<GoRedirect />} />
              <Route path="/subscribe" element={<SubscribePage />} />
              <Route path="/my" element={<My />} />
              <Route path="/myquiz" element={<MyQuiz />} />
              <Route path="/family/:familyName" element={<Family />} />
              <Route path="/family" element={<Family />} />
              <Route path="/privacy" element={<PrivacyLayout />} />
              <Route path="/security" element={<PrivacyLayout />} />
              <Route path="/cookie" element={<PrivacyLayout />} />
              <Route path="/qr" element={<QRCodeGenerator />} />
              <Route path="/tc" element={<ThumbnailCreator />} />
              <Route path="/logo" element={<ThumbnailCreator />} />
              <Route path="/food" element={<Food />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/create-video-with-ai" element={<GuideMD />} />
              <Route path="/ai" element={<GuideMD />} />
              <Route path="/banner" element={<ThumbnailCreator />} />
              <Route path="/aiserver" element={<GuideMDServer />} />
              <Route path="/audio" element={<TextToAudioConverter />} />
              <Route path="/example" element={<Examples />} />
              <Route path="/training" element={<Examples />} />
              <Route path="/tags" element={<Tags />} />
              <Route path="/tag/:tagName" element={<Tags />} />
              <Route path="/chat" element={<ChatGPT />} />
              <Route path="/chat-vieu" element={<ChatGPTVieu />} />
              <Route path="/chat-oodle" element={<ChatGPTOodle />} />
              <Route path="/chat-pdx" element={<ChatGPTPDX />} />
              <Route path="/chatbot" element={<ChatBot />} />
              <Route
                path="/create-chatbot/:botId"
                element={<ChatbotCreator />}
              />

              <Route path="/create-chatbot/" element={<ChatbotCreator />} />

              <Route path="/chat-admin/*" element={<ChatAdmin />} />
              <Route path="/iframe-embed" element={<ChatBotIframePage />} />
              <Route
                path="/iframe-embed-vieu"
                element={<ChatBotIframeVieuPage />}
              />
              <Route
                path="/iframe-embed-pdx"
                element={<ChatBotIframePDXPage />}
              />

              <Route
                path="/iframe-embed-oodle"
                element={<ChatBotIframeOodlePage />}
              />
              <Route path="/blog-to-video/:blogId" element={<BlogToVideo />} />
              <Route path="/blog-to-video/*" element={<BlogToVideo />} />
              <Route path="/mission/*" element={<Mission />} />
              <Route path="*" element={<CatchAll />} />
            </Routes>
          </Suspense>
        </div>
      </div>

      {!shouldHideFooter && !isIFrameEmbed && <Footer />}

      <div className="left-0 right-0 -z-10 bg-white m-2 p-2">
        {!isVideoEmbed && shouldHideFooter && !isPup && !isIFrameEmbed && (
          <p className="text-center text-sm text-gray-500 my-6">
            Please email{" "}
            <a
              href="mailto:admin@kimavi.com"
              className="text-blue-500 underline"
            >
              admin@kimavi.com
            </a>{" "}
            for help and support
          </p>
        )}
      </div>
      {isVideoEmbed && shouldHideFooter && (
        <div className="text-left p-2 text-xs">
          Powered by{" "}
          <a
            href="https://www.kimavi.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kimavi
          </a>
        </div>
      )}
    </>
  );
}

function App() {
  return (
    <HelmetProvider>
      <Router>
        <AppContent />
      </Router>
    </HelmetProvider>
  );
}

export default App;
